
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Blogs from "../component/Home/Blogs";

function BlogsPage() {
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="d-flex">
            <Sidebar />
        </div>

        <Blogs />
        <Section />
        <Footer/> 
    </div>
    );
}

export default BlogsPage;