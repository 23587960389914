import Register from "../component/Home/Register";
import {GlobalStyles } from "../component/styles/Global.styled";
import ScrollToTop from "../component/Home/ScrollToTop";

function RegisterPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <Register />
    </div>
    );
}

export default RegisterPage;
