import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import Trending from "../component/Home/Trending";
import Blockbuster from "../component/Home/Blockbuster";
import Channels from "../component/Home/Channels";
import TopofWeek from "../component/Home/TopofWeek";
import Geners from "../component/Home/Geners";
import MoviesFor from "../component/Home/MoviesFor";

function MoviesPage() {
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="section-flex">
            <Sidebar />                
            <Indexhero/>
        </div>

        <Trending heading="Trending"/>
        <Blockbuster  heading="Block Buster"/>

        <TopofWeek heading="Top 10 Movie Of The Week"/>
        <Geners heading="Movies Geners"/>

        <MoviesFor heading="Movies For You"/>
        <Channels heading="New Release"/>

        <Section />
        <Footer/> 
    </div>
    );
}

export default MoviesPage;
