
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import Trending from "../component/Home/Trending";
import Blockbuster from "../component/Home/Blockbuster";
import Channels from "../component/Home/Channels";
import Geners from "../component/Home/Geners";
import MoviesFor from "../component/Home/MoviesFor";
import EpisodeTabs from "../component/Home/EpisodeTabs";

function SeriesPage() { 
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="section-flex">
            <Sidebar />        
            <Indexhero/>
        </div>

        <EpisodeTabs/>
        <Trending heading="New Release"/>

        <Trending heading="Trending"/>
        <Blockbuster  heading="Top Picks For You"/>

        <Geners heading="Series Geners"/>
        <MoviesFor heading="Recommendation For You"/>

        <Section/>
        <Footer/>
    </div>
    );
}

export default SeriesPage;
