import "../../style/Home/Geners.css";
import "../../style/Home/Common.css";
import "../../style/Home/Channels.css";
import "../../style/Home/TopTvShows.css";
import "../../style/Home/Trending.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import final from "../../images/final.png";
import channel1 from "../../images/channel1.png";
import channel2 from "../../images/channel2.png";
import channel3 from "../../images/channel3.png";
import Slider from "react-slick";
import { Container} from "react-bootstrap";
import { useNavigate } from "react-router";

function Geners(props) { 
    const navigate= useNavigate();
    const settings1 = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        centerPadding: "-16px",
        margin:"30px",
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        slidesToShow: 4,
        swipeToSlide: true,
        arrows: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1500,
                settings: 
                {
                    slidesToShow:3.5,
                    slidesToScroll: 3.5,
                    infinite: true,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 1400,
                settings: 
                {
                    slidesToShow:4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 1200,
                settings: 
                {
                    slidesToShow:3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    centerPadding: "-20px",
                },
            },
            {
                breakpoint: 991,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    centerPadding: "-20px",
                },
            },
            {
                breakpoint: 850,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2.5,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: 
                {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 600,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 420,
                settings: 
                {
                    centerPadding: "-10px",
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className="hero-slick relative geners channels">
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <div className="thumbnail-slider-wrap">
                <style>{cssstyle}</style>
                <Container className="pt-40 px-100 pb-5">
                    <div className="heading-text">
                            <h4 className="color-white my-0">{props.heading}</h4>
                        <a onClick={ ()=>navigate('/channels')} className="text-decoration-none">
                        <h4 className="color-white my-0">See All</h4>
                    </a>
                    </div>
                    <hr></hr>
                    <Slider {...settings1} className="pt-3">
                        <div>
                            <div className="flip">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel1} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image" src={channel2} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel3} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel1} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel2}  />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Container>
            </div>
        </div>
    );
}
export default Geners;
const cssstyle = ` 
.geners .slick-dots{
    position:relative !important;
    bottom:0px !important;
}
.geners{
    margin-top:0px !important;
}`;
