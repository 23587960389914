import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import channel1 from "../../images/channel1.png";
import channel2 from "../../images/channel2.png";
import channel3 from "../../images/channel4.png";
import channel4 from "../../images/channel5.png";
import channel5 from "../../images/channel6.png";
import channel6 from "../../images/channel3.png";
import { Container, Row, Col } from "react-bootstrap";
import {FaRegHeart,FaPlay} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";

function AllChannels(props) {
const navigate =useNavigate();

    return (
        <Section>
            <Container className="mt-100 px-100 relative pt-sm-50 mb-4">
                <h3 className="text-white text-center">All Channels</h3>
                <hr></hr>
                <Row className="pt-3">
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                        <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel1} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel3} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel4} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel5} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>   
                <Row className="pt-3">
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel6} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel1} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel2} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-12 mb-4">
                    <div className="col-zoom">
                            <div className="flip1">
                                <div className="front channel-bg bg-darker">
                                        <img className="slick-slide-image " src={channel3} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );

}
export default AllChannels;
