
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Header from "../component/Home/Header";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import Trending from "../component/Home/Trending";
import PopularShows from "../component/Home/PopularShows";
import Channels from "../component/Home/Channels";
import Popularseries from "../component/Home/Popularseries";
import MoviesFor from "../component/Home/MoviesFor";
import EpisodeTabs from "../component/Home/EpisodeTabs";

function TvShowsPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   
        
        <div className="section-flex">
            <Sidebar />        
            <Indexhero/>
        </div>

        <EpisodeTabs/>
        <Trending heading="Popular Shows"/>
        <Popularseries heading="Popular In Reality" />
        
        <PopularShows heading="Must Watch" />
        <MoviesFor heading="Recommendation For You"/>
        <Channels heading="Popular Channels"/>
        <Section/>
        <Footer/>
    </div>
    );
}

export default TvShowsPage;
