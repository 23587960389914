
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import ScrollToTop from "../component/Home/ScrollToTop";
import IntroDetail from "../component/Home/IntroDetail";  

function IntroPages() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />       

        <IntroDetail/>     
        <Section/>
    </div>
    );
}

export default IntroPages;
