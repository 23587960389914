import "../../style/Home/ContactUs.css";
import "../../style/Home/Common.css";
import React from "react";
import lottie from "lottie-web";
import location from "../../images/location.png"
import mail from "../../images/mail.png"
import call from "../../images/call.png"
import animate from "../../lottie/animate.json";
import GoogleMapReact from 'google-map-react';
import { Container} from "react-bootstrap";
import { useNavigate } from "react-router";
import {FaChevronRight } from "react-icons/fa";
import { Section } from "../styles/Section.styled";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const ContactUs = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#contact"),
          animationData: animate,
          loop: true
        });
      }, []);

      const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
      };
    return (
        <>
        <Section>
            <div className="bg-about">
                <div className="about-bg-image my-120">
                    <h1 className="text-white text-center">Contact Us</h1>
                    <span className="d-flex justify-content-center">
                        <a onClick={() => navigate("/")}>
                            <p className="mb-0">Home</p>
                        </a>
                        <FaChevronRight className="text-white mt-1 mx-2" />
                        <p className="color-orange mb-0">Contact Us</p>
                    </span>
                </div>
            </div>
            <div className="bg-darker">                    
                <Container className="relative px-100 py-5">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 px-3 mb-3">
                            <div className="text-center text-white address-border">
                                <img src={location} alt="location" />
                                <h5 className="pt-2">Come See Us</h5>
                                <p className="mb-0">Townhouse Consoling,<br/> LLC 50 ean 2 See, New York NY 20159</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 px-3 mb-3">
                            <div className="text-center text-white address-border shadow">
                                <img src={mail} alt="mail" />
                                <h5 className="pt-2">Contact via Email</h5>
                                <p className="mb-0">loopweb@gmail.com</p>
                                <p className="mb-0">looptv@gmail.com</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 px-3 mb-3">
                            <div className="text-center text-white address-border shadow">
                                <img src={call} alt="mail" />
                                <h5 className="pt-2">Contact via Number</h5>
                                <p className="mb-0">+ 123 765 9756</p>
                                <p className="mb-0">+ 435 321 6543</p>
                            </div>
                        </div>
                    </div>
                </Container>                 
                <div className="py-3 container-fluid">
                    <div style={{ height: '500px', width: 'auto' }}>
                        <GoogleMapReact bootstrapURLKeys={{ key: "" }} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom} >
                            <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
                        </GoogleMapReact>
                    </div>
                </div>                    
                <Container className="relative px-100 py-5">
                    <div className="row reverse">
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="text-white"><h1 className="color-orange mb-lg-4">Get In Touch</h1></div>
                            <form action="#" className="form-outline">
                                <div className="form-row">
                                    <div className="input-data" >
                                        <input type="text" required />
                                        <div className="underline"></div>
                                        <label for="">First Name</label>
                                    </div>
                                    <div className="input-data">
                                        <input type="text" required />
                                        <div className="underline"></div>
                                        <label for="">Last Name</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input type="text" required />
                                        <div className="underline"></div>
                                        <label for="">Email Address</label>
                                    </div>
                                    <div className="input-data">
                                        <input type="text" required />
                                        <div className="underline"></div>
                                        <label for="">Phone Number</label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data textarea">
                                        <textarea rows="8" cols="80" required></textarea>
                                        <br />
                                        <div className="underline"></div>
                                        <label for="">Write your message</label>
                                        <br />
                                    </div>
                                </div>                                    
                                <div className="form-row submit-btn">
                                    <div className="input-data">
                                        <div className="inner"></div>
                                        <input type="submit" value="submit" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12">
                            <div id="contact" />
                        </div>
                    </div>
                </Container>   
            </div>
        </Section>
        </>
    );
};
export default ContactUs;
