import "../../style/Home/SignIn.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Register.css";
import $ from "jquery";
import final from "../../images/final.png";
import mobileicon from "../../images/mobileicon.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";
import { useState } from "react";

function Register() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    $(document).ready(function () {
        var current_fs, next_fs, previous_fs; //fieldsets
        var opacity;
        var current = 1;
        var steps = $("fieldset").length;
        setProgressBar(current);
        $(".next").click(function () {
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();
            //Add Class Active
            $("#progressbar li")
                .eq($("fieldset").index(next_fs))
                .addClass("active");
            //show the next fieldset
            next_fs.show();
            //hide the current fieldset with style
            current_fs.animate(
                { opacity: 0 },
                {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            display: "none",
                            position: "relative",
                        });
                        next_fs.css({ opacity: opacity });
                    },
                    duration: 500,
                }
            );
            setProgressBar(++current);
        });
        $(".previous").click(function () {
            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();
            //Remove class active
            $("#progressbar li")
                .eq($("fieldset").index(current_fs))
                .removeClass("active");
            //show the previous fieldset
            previous_fs.show();
            //hide the current fieldset with style
            current_fs.animate(
                { opacity: 0 },
                {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            display: "none",
                            position: "relative",
                        });
                        previous_fs.css({ opacity: opacity });
                    },
                    duration: 500,
                }
            );
            setProgressBar(--current);
        });
        function setProgressBar(curStep) {
            var percent = parseFloat(100 / steps) * curStep;
            percent = percent.toFixed();
            $(".progress-bar").css("width", percent + "%");
        }

        $(".submit").click(function () {
            return false;
        });
    });

    return (
        <Section>
            <div className="d-flex justify-content-center bg-images">
                <div className="registration-form pt-3">            
                <div className="register-form-content text-center">
                        <h5 className="text-white">Welcome!</h5>
                        <h2 className="text-white font-w-600">Unlimited movies, TV shows and more</h2>
                        <h5 className="text-white">Watch anywhere. Cancel anytime.</h5>
                </div>
                <div className="sign-bg mt-3">
                    <div className="justify-content-center text-center">
                        <form id="msform">
                            <fieldset className="register-btn">       
                                <div className="form-card ">
                                    <Modal.Header>
                                            <div className="Brand d-none d-lg-block">
                                                <a href="">
                                                    <img src={final} alt="final" />
                                                </a>
                                            </div>
                                            <div className="mobile-logo1 d-block d-lg-none">
                                                <a href="">
                                                    <img src={mobileicon} alt="final" />
                                                </a>
                                            </div>
                                    </Modal.Header>
                                    <div className="text-center pt-3">
                                        <h2 className="text-white font-w-600"> Sign Up </h2>
                                        <p className="pt-2"> Enjoy Loop For FREE Entertainment </p>
                                        <form className="mb-4">
                                            <div className="pt-2">
                                                <input type="text" className="form-control input-bg" id="your-email" name="your-email" required placeholder="Email or Phone Number" ></input>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <input type="button" name="next" className="next action-button" value="Next" />
                                <div className="mini-heading">
                                    <p className="text-white pt-3 text-start">
                                        New In Loop ?<span className="color-orange"> Sign Up </span>
                                    </p>
                                </div>
                                <Modal.Footer>
                                    <Button variant="" onClick={ ()=>navigate('/home')} className="text-white text-end" > Skip </Button>
                                    <a onClick={ ()=>navigate('/planspage')}><Button variant="" className="text-white text-end" > Previous </Button></a>
                                </Modal.Footer>                           
                            </fieldset>
                            <fieldset>
                                <div className="register-btn">                                    
                                    <div className="form-card ">
                                        <Modal.Header className="">
                                            <div className="Brand d-none d-lg-block">
                                                <a href="">
                                                    <img src={final} alt="final" />
                                                </a>
                                            </div>
                                            <div className="mobile-logo1 d-block d-lg-none">
                                                <a href="">
                                                    <img src={mobileicon} alt="final" />
                                                </a>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body className="text-center pt-4">
                                            <h2 className="text-white font-w-600">Verify</h2>
                                            <p className="pt-2">Enjoy Loop For FREE Entertainment</p>
                                            <form>
                                                <div className="row g-3 pt-2">
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>1</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>2</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>3</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>4</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>5</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center">
                                                        <div className="otp-number">
                                                            <p>6</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between mini-heading">
                                                    <p>Resend OTP</p>
                                                    <p>0:60</p>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </div>
                                    <button type="button" name="Verify" className="action-button" onClick={ ()=>navigate('/home')} value="Verify" >Verify</button>  
                                </div>                                         
                                <input type="button" name="previous" className="previous action-button-previous" value="Previous" />                               
                                <Modal.Footer>
                                    <Button variant="" onClick={ ()=>navigate('/home')} className="text-white text-end mt-1" > Skip </Button>
                                </Modal.Footer>          
                            </fieldset>
                        </form>
                    </div>        
                </div>
                </div>   
            </div>  
        </Section>
    );
}

export default Register;
