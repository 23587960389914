import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import raaz from "../../images/raaz.jpg";
import deadwind from "../../images/deadwind.jpg";
import moneyheist from "../../images/moneyheist.jpg";
import sintter from "../../images/sintter.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegHeart,FaPlay} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";

function TopSearches() {
const navigate = useNavigate();

return (
    <Section>
        <Container className="mt-100 px-100">
            <div className="heading-text">
                <a href="" className="text-decoration-none">
                    <h4 className="color-white my-0">Top Search</h4>
                </a>
            </div>
            <hr></hr>
            <Row className="pt-3">
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={raaz} alt="raaz" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Raaz </h5>
                                        <div className="extra-small-content">
                                            <p className=""> 
                                                Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={deadwind} alt="deadwind" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Deadwind</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={moneyheist} alt="moneyheist" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Money Heist</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={sintter} alt="sintter" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Sintter</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>   
        </Container>
    </Section>
);
}
export default TopSearches;
