import "../../style/Home/Common.css"

    export const light = {
        name: "light-theme",
        colors: {
        header: "linear-gradient(to right, #212121, #89601E, #822719 )",
        background: "linear-gradient(to right, #212121, #89601E, #822719 )",
        footer: "#403a31",
        text: "#fff",
        color:"var( --primary-color)",
        quoteBgc: "hsl(60, 40%, 100%)",
        quoteTitle: "hsl(0, 1%, 38%)",
        quoteBody: "hsl(0, 1%, 38%);",
        quoteBorder: "hsl(0, 0%, 87%)",
        border: "var( --primary-color)",
        hover:"var( --primary-color)",
        btn:"var( --primary-color)",
        active:"var( --primary-color)",
        borderbottom:"2px solid  rgb(132 99 47 / 55%)",
        hovergradient:"linear-gradient(to right, #212121, #89601e)",
        contactradial:"radial-gradient(circle, rgb(132 99 47 / 55%) 0%, rgb(31 24 11) 100%)",
        contactbtn:"-webkit-linear-gradient(right, #faa31a, #6b470c, #967d54, #614520)",
        gradient:"linear-gradient(to right,hsl(39deg 100% 48.25% / 43%),hsl(222deg 100% 58% / 0))",
        footerbg:"linear-gradient(180deg, #89601e -20%,#000 100%);"
      },
  };
    
    export const blue = {
        name: "blue-theme",
        colors: {
        header: "linear-gradient(to left, #52A9E2, #00C7FF, #0079FF)",
        background:"linear-gradient(to left, #52A9E2, #00C7FF, #0079FF)",
        footer: "#403a31",
        text: "#fff",
        color:"var( --blue-color)",
        quoteBgc: "hsl(0, 0%, 35%)",
        quoteTitle: "hsl(0, 0%, 100%)",
        quoteBody: "hsl(0, 0%, 100%)",
        quoteBorder: "hsl(0, 0%, 59%)",
        border: "var( --blue-color)",
        hover:"var( --blue-color)",
        btn:"var( --blue-color)",
        active:"#0470ad",
        borderbottom:"2px solid rgb(47 75 132 / 55%)",
        hovergradient:"linear-gradient(to right, #212121, #0082e0)",
        contactradial:"radial-gradient(circle,  #00a5d5 0%, rgb(31 24 11) 100%)",
        contactbtn:"-webkit-linear-gradient(right, #1a96fa, #667986, #53a3ff, #53beff)",
        gradient:"linear-gradient(to right,hsl(202.38deg 70.96% 51.74%),hsl(222deg 100% 58% / 0))",
        footerbg:" linear-gradient(180deg,#00c7ff57 -20%,#000 100%);"
        },
    };
    
    export const red = {
        name: "red-theme",
        colors: {
        header: "linear-gradient(to right, #3E0201, #8F2318, #B22D17, #A80805 )",
        background: "linear-gradient(to right, #3E0201, #8F2318, #B22D17, #A80805 )",
        footer: "#403a31",
        text: "#fff",
        color:"var( --red-color)",
        quoteBgc: "hsl(0, 0%, 100%)",
        quoteTitle: "hsl(195, 52%, 28%)",
        quoteBody: "hsl(0, 0%, 38%)",
        quoteBorder: "hsl(0, 0%, 87%)",
        border: "var( --red-color)",
        hover:"var( --red-color)",
        btn:"var( --red-color)",
        active:"#ff5140",
        borderbottom:"2px solid  rgb(132 47 47 / 55%)",
        hovergradient:"linear-gradient(to right, #130303, #af0000)",
        contactradial:"radial-gradient(circle,#d11e00a1 0%, rgb(31 24 11) 100%)",
        contactbtn:"-webkit-linear-gradient(right, #e20808, #c60e0e, #b46363, #e90303)",
        gradient:"linear-gradient(to right,hsl(0deg 83.48% 51.93%),hsl(222deg 100% 58% / 0))",
        footerbg:" linear-gradient(180deg,#b22d17 -20%,#000 100%);"
        },
    };
  
  