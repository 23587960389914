
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import TopSearches from "../component/Home/TopSearches";
import MoviesFor from "../component/Home/MoviesFor";
import HeaderSearch from "../component/Home/HeaderSearch";
import SearchOnly from "../component/Home/SearchOnly";

function TopSearchPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <HeaderSearch/> 

        <div className="d-flex">
            <Sidebar />        
        </div>

        <SearchOnly/>
        <TopSearches />  
        <MoviesFor heading="Recommendation For You"/>
        <Section/>
        <Footer/>
    </div>
    );
}

export default TopSearchPage;
