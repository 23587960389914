import "../../style/Home/UserProfile.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import EditUser from "../Home/EditUser.js"
import image3 from "../../images/image3.png";
import image2 from "../../images/image2.png";
import budha from "../../images/budha.png";
import favourite from "../../images/favourite.png";
import image1 from "../../images/image1.png";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaHeart} from "react-icons/fa";
import { Section } from "../styles/Section.styled";

function UserProfile(props) {
const navigate = useNavigate()
    
return (
    <Section>
        <Container className="mt-100 pt-sm-50 px-100 mb-0">
            <div className="edit-button text-end">
                <EditUser/>
            </div>
            <hr></hr>
            <div className="text-center pt-lg-4">
                <div className="user-profile-img">
                    <img src={budha} alt="budha" />
                </div>
                <div className="pt-2">        
                    <h5 className="text-white">Email Address: user@gmail.com</h5>
                </div>
            </div>
            {/* Continue watching section  */}
            <div className="pt-40 relative">
                <div className="heading-text">
                    <a href="" className="text-decoration-none">
                        <h4 className="color-white my-0">Continue Watching</h4>
                    </a>
                </div>
                <hr></hr>
                <Row className="pt-3">
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div>
                            <div id="main-continue">
                                <div className="continue-content">                                
                                    <div className="content-overlay"></div>
                                    <a href="">
                                        <img src={budha} alt="budha" />
                                    </a>
                                    <div className="content-details fadeIn-left">
                                        <h5 className="text-white">00:25:50</h5>
                                    </div>
                                </div>
                                <div className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">KGF Chapter 2</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <FaHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div>
                            <div id="main-continue">
                                <div className="continue-content">                                
                                    <div className="content-overlay"></div>
                                    <a href="">
                                        <img src={image3} alt="image3" />
                                    </a>
                                    <div className="content-details fadeIn-left">
                                        <h5 className="text-white">00:25:50</h5>
                                    </div>
                                </div>
                                <div className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">KGF Chapter 2</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <FaHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div>
                            <div id="main-continue">
                                <div className="continue-content">                                
                                    <div className="content-overlay"></div>
                                    <a href="">
                                        <img src={image2} alt="image2" />
                                    </a>
                                    <div className="content-details fadeIn-left">
                                        <h5 className="text-white">00:25:50</h5>
                                    </div>
                                </div>
                                <div className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">KGF Chapter 2</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <FaHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div>
                            <div id="main-continue">
                                <div className="continue-content">                                
                                    <div className="content-overlay"></div>
                                    <a href="">
                                        <img src={image1} alt="image1" />
                                    </a>
                                    <div className="content-details fadeIn-left">
                                        <h5 className="text-white">00:25:50</h5>
                                    </div>
                                </div>
                                <div className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">KGF Chapter 2</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <FaHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* Favourite list section  */}
            <div className="pt-40 relative">
                <div className="heading-text">
                    <a href="" className="text-decoration-none">
                        <h4 className="color-white my-0">My List</h4>
                    </a>
                </div>
                <hr></hr>
                <div className="pt-lg-5">
                    <div className="text-center">
                        <a href=""><img src={favourite} alt="favourite" /></a>
                        <h4 className="color-white font-w-500 py-lg-5">You haven't added anything yet</h4>
                    </div>
                </div>
            </div>
        </Container>
    </Section>
);
}
export default UserProfile;
