import "../../style/Home/TopofWeek.css";
import "../../style/Home/Common.css";
import "swiper/css";
import "@fontsource/inter";
import moneyheist from "../../images/moneyheist.jpg";
import thewitcher from "../../images/thewitcher.jpg";
import line from "../../images/line.jpg";
import squidgames from "../../images/squidgames.jpg";
import siliconvalley from "../../images/siliconvalley.jpg";
import justified from "../../images/justified.jpg";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import {Navigation, Autoplay} from "swiper/modules";
import { Section } from "../styles/Section.styled";

function TopofWeek(props) { 
    const navigate=useNavigate();
    return (
      <Section>
            <div className="pt-40 relative">
                <Container className="px-100">
                    <div className="heading-text ">
                        <a href="" className="text-decoration-none">
                            <h4 className="color-white my-0">{props.heading}</h4>
                        </a>
                    </div>
                    <hr></hr>
                    <div className="pt-3 ">
                        <Swiper modules={[Navigation, Autoplay]} spaceBetween={10} slidesPerView={4.5}
                            breakpoints=
                            {{
                                0: {
                                    slidesPerView: 2,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                639: {
                                    slidesPerView: 3,
                                },
                                865: {
                                    slidesPerView: 3,
                                },
                                1000: {
                                    slidesPerView: 4,
                                },
                                1400: {
                                    slidesPerView: 4,
                                },
                                1500: {
                                    slidesPerView: 4.5,
                                },
                            }}
                            autoplay={true}
                            loop={true}
                            navigation
                            onSwiper={(swiper) => console.log()}
                        >
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={squidgames} alt="squidgames" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">1</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={line} alt="line" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">2</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={moneyheist} alt="moneyheist" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">3</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a href=""><img src={thewitcher} alt="thewitcher" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">4</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a href=""><img src={siliconvalley} alt="siliconvalley" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">5</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a href=""><img src={justified} alt="justified" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">6</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a href=""><img src={thewitcher} alt="thewitcher" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">7</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={line} alt="line" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">8</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={squidgames} alt="squidgames" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">9</h1>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="top-10 relative">
                                    <div id="top-show">
                                        <a onClick={ ()=>navigate('/Series')}><img src={moneyheist} alt="moneyheist" /></a>
                                        <a onClick={ ()=>navigate('/Series')}> 
                                            <button className="movies-btn "> <FaPlay /></button>
                                        </a>
                                        <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                    </div>
                                    <h1 className="top-number">10</h1>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Container>
            </div>
      </Section>
    );
}

export default TopofWeek;
