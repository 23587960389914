import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import MoreLike from "../component/Home/MoreLike";
import Trailer from "../component/Home/Trailer";
import VideoPlayer from "../component/Home/VideoPlayer"

function TvShowsPage() {
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   
            <Sidebar />                
            <VideoPlayer/>

        <MoreLike/>
        <Trailer heading="Trailer & More"/>
        <Section />
        <Footer/> 
    </div>
    );
}

export default TvShowsPage;
