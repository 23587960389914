import "../../style/Home/SignIn.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Register.css";
import "../../style/Home/Common.css";
import "../../style/Home/Navbar.css";
import Button from "react-bootstrap/Button";
import $ from "jquery";import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function SignIn() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

return (
<>
<div className="">
    <a href="#" className="pe-3">
        <Button className="custom-btn btn-15" variant="primary" onClick={ ()=>navigate('/signin')}  > Sign In </Button>
    </a>
</div>       
</>
);
}

export default SignIn;
