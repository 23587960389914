import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import "../../style/Home/SignIn.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Plan.css";

function Plan() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (      
<>
    <div className="">
        <a href="#" className="">
            <Button className="custom-btn btn-15" variant="primary" onClick={ ()=>navigate('/planspage')} > Register </Button>
        </a>
    </div>
</>
    );
}

export default Plan;
