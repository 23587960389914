import "../../style/Home/AboutUs.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import { Container} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import {FaChevronRight} from "react-icons/fa";
import { Section } from "../styles/Section.styled";

function TermUse(props) {
    const navigate = useNavigate()
    
    return (
        <Section>
            <div className="relative ">
                <div className="about-bg-image my-120">
                    <h1 className="text-white text-center">Terms & Use</h1>
                    <span className="d-flex justify-content-center">
                        <a onClick={()=>navigate('/')}>
                            <p className="mb-0">Home</p>
                        </a>
                        <FaChevronRight className="text-white mt-1 mx-2"/>
                        <p className="color-orange mb-0">Terms & Use</p>
                    </span>
                </div>
                <div className="bg-darker py-5">
                    <Container>            
                        <div className="">
                            <h2 className="text-center text-white">Terms & Use Statement</h2>
                        </div>
                        <div className="pt-4">
                            <p>
                                Loop provides a personalized subscription service that allows our members to access entertainment content (“Loop content”) over 
                                the Internet on certain Internet-connected TVs, computers and other devices ("Loop ready devices").
                                These Terms of Use govern your use of our service. As used in these Terms of Use, "Loop service", "our service" 
                                or "the service" means the personalized service provided by Loop for discovering and accessing Loop content, 
                                including all features and functionalities, recommendations and reviews, our websites, and user interfaces, as well as 
                                all content and software associated with our service. References to ‘you’ in these Terms of Use indicate the member 
                                who created the Loop account and whose payment method is charged.
                            </p>
                        </div>
                        <div className="pt-4">
                            <h5 className="text-white">
                                1. Promotional Offers : 
                            </h5>
                            <p className="pt-3">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            </p>
                        </div>
                        <div className="pt-4">
                            <h5 className="text-white">
                                2. Billing and Cancellation :
                            </h5>
                            <p className="pt-3 ">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                                quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                                arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                                Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                                Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            </p>
                        </div>
                    </Container>
                </div>
            </div>
        </Section>
    );

}
export default TermUse;
