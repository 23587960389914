import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import agent from "../../images/agent.jpg";
import deadwind from "../../images/deadwind.jpg";
import freddy from "../../images/freddy.jpg";
import extraction from "../../images/extraction.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaRegHeart,FaPlay} from "react-icons/fa";
import { Section } from "../../component/styles/Section.styled";

function Blockbuster(props) {
    const navigate = useNavigate()
    
    return (
       <Section>
            <Container className="pt-40 px-100">
                <div className="heading-text">
                    <h4 className="color-white my-0">{props.heading}</h4>
                    <a onClick={ ()=>navigate('/seeall')} className="text-decoration-none">
                        <h4 className="color-white my-0">See All</h4>
                    </a>
                </div>
                <hr></hr>
                <Row className="pt-3">
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={agent} alt="agent" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Agent</h5>
                                            <div className="extra-small-content">
                                                <p className=""> 
                                                    Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={deadwind} alt="deadwind" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Deadwind</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={freddy} alt="freddy" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Freddy</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={extraction} alt="extraction" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Extraction</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>   
            </Container>
       </Section>
    );

}
export default Blockbuster;
