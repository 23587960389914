
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import AboutUs from "../component/Home/AboutUs";
import React, { useState } from "react";
function AboutUsPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   
        <div className="d-flex">
            <Sidebar />
        </div>
        <AboutUs />
        <Section />
        <Footer/>
    </div>
    );
}

export default AboutUsPage;