import "../../style/Home/HeaderSearch.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import final from "../../images/final.png";
import SignIn from "./SignIn";
import Plan from "./Plan";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import mobileicon from "../../images/mobileicon.png";
import Register from "./Register";
import { useNavigate } from "react-router-dom";
import { FaThLarge, FaSearch, FaTimes } from "react-icons/fa";
import { Header } from "../styles/Header.styled";
import { Container} from "react-bootstrap";

function Navbar() {
const navigate = useNavigate();

return (
    <Header>
        <nav className="navbar header">
            <Container className="px-100">
                <div className="navbar-container main-nav">
                    <input type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <FaThLarge className="mobile-icon" />
                    </div>
                    <div className="Brand">
                        <a href="" onClick={() => navigate("/")}>
                            <img src={final} alt="final" />
                        </a>
                    </div>
                    <div className="mobile-logo">
                        <a href="" onClick={() => navigate("/")}>
                            <img src={mobileicon} alt="final" />
                        </a>
                    </div>
                    <div>
                        <div className="bg-search rounded rounded-pill shadow-sm mt-3">
                            <div className="input-group">
                                <input type="text"  placeholder="" aria-describedby="button-addon1"  className="form-control border-0 bg-search" />
                                <div className="input-group-append">
                                    <button id="button-addon1" type="submit" className="btn btn-link text-primary" >
                                        <FaSearch className="text-white search-icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="menu-items header-two">
                        <div className="Sign-btn Sign-show">
                            <a href="#">
                                <SignIn/>
                                <Plan />
                            </a>
                        </div>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Movies">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/movies')}>All Movies</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Series">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/series')}>All Series</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="TV Shows">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/tvshows')}>All Series</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li>
                            <a onClick={ ()=>navigate('/newandhot')}>
                                New & Hot
                            </a>
                        </li>
                        <li>
                            <a onClick={ ()=>navigate('/channels')}>
                                Channels
                            </a>
                        </li>                        
                        <li className="d-flex mt-16">
                            <DropdownButton title="Pages">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/aboutus')}>About Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/faqpage')}>FAQ's</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/contactpage')}>Contact Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/blogspage')}>Blogs</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/privacypage')}>Privacy Policy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/termspage')}>Terms & Use</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                    </ul>
                </div>
            </Container>
        </nav>
    </Header>
);

}
export default Navbar;
