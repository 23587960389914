import { useState } from "react";
import "../../style/Home/SignIn.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Plan.css";
import "../../style/Home/Navbar.css";
import Button from "react-bootstrap/Button";
import final from "../../images/final.png";
import google from "../../images/google.png";
import fb from "../../images/fb.png";
import mobileicon from "../../images/mobileicon.png";
import Modal from "react-bootstrap/Modal";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function Plan() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (      
<>
    <div className="">
        <a href="#" className="pe-3">
            <Button className="custom-btn btn-15" variant="primary" onClick={ ()=>navigate('/planspage')}> Go Pro </Button>
        </a>
    </div>
</>
    );
}

export default Plan;
