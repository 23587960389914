import styled from "styled-components";

export const Header = styled.header`
    background: ${({ theme }) => theme.colors.background};
    padding: 0px 0px;
    text-align: center;
    position:fixed;
    top:0;
    width:100%;
    z-index:1000;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text};
    
    a:hover {
        color: ${({ theme }) => theme.colors.hover};
    }
    .Sign-btn button {
        background: transparent !important;
        border: 2px solid ${({ theme }) => theme.colors.border} !important;
    }
    .Sign-btn button:hover {
        background-color: ${({ theme }) => theme.colors.btn}!important;
        border: none !important;
    }
    .Sign-btn button:after {
        background-color: ${({ theme }) => theme.colors.btn}!important;
        border: none !important;
    }
    .dropdown .dropdown-item:hover {
        background: ${({ theme }) => theme.colors.gradient}!important;
        border-left: 3px solid ${({ theme }) => theme.colors.btn}!important;
    }
    .menu-items li .active {
        color: ${({ theme }) => theme.colors.active}!important;
        border: none !important;
    }
    .side-list ul li a .side-icons:hover {
        color: ${({ theme }) => theme.colors.active}!important;
    }
`;
