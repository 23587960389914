import "../../style/Home/Trendingseries.css";
import Slider from "react-slick";
import family from "../../images/family.png";
import image_1 from "../../images/image_1.jpg";
import image_2 from "../../images/image_2.jpg";
import image_3 from "../../images/image_3.jpg";
import image_4 from "../../images/image_4.jpg";
import image_5 from "../../images/image_5.jpg";
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";
import React, { useState, useEffect } from "react";

function Trendingseries() {
    const navigate=useNavigate();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        fade: true,
        asNavFor: ".slider-nav",
    };

    const settingsThumbs = {
        className: "center",
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        swipeToSlide: true,
        spaceBetween: 10,
        focusOnSelect: true,
        margin: "30px",
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 895,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                },
            },
        ],
    };

    const slidesData = [
        {
            id: 1,
            photo: image_1,
        },
        {
            id: 2,
            photo: image_2,
        },
        {
            id: 3,
            photo: image_3,
        },
        {
            id: 4,
            photo: image_4,
        },
        {
            id: 5,
            photo: image_5,
        },
        {
            id: 6,
            photo: family,
        },
    ];

return (
    <Section className="py-5">
        <div className="relative bg-carousel-height">
            <div className="sm-show text-mid-center">
                <h1 className="color-orange text-md-center">Trending Series</h1>
            </div>
            <div className="slider-wrapper">
                <style>{cssstyle}</style>
                <Slider className="large-slider" {...settingsMain} asNavFor={nav2} ref={(slider) => setSlider1(slider)}>
                    {slidesData.map((slide) => (
                        <div className="slick-slide " key={slide.id}>
                            <img className="slick-slide-image large-imgs" src={slide.photo} />
                        </div>
                    ))}
                </Slider>
                <Container className="carousel-up">
                    <div className="row">
                        <div className="col-lg-4 col-12 my-150  sm-hide text-center">
                            <h1 className="color-orange text-mid-center">Trending <br></br>Series</h1>
                        </div>
                        <div className="col-lg-8 col-12">
                            <div className="thumbnail-slider-wrap">
                                <style>{cssstyle}</style>
                                <Slider {...settingsThumbs} asNavFor={nav1}  ref={(slider) => setSlider2(slider)} >
                                    {slidesData.map((slide) => (
                                        <div className="slick-slide my-lg-4" key={slide.id} >
                                            <a className="text-decoration-none" onClick={ ()=>navigate('/Series')}> 
                                                <img className="slick-slide-image small-imgs" src={slide.photo} />
                                            </a>  
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    </Section>
);
}

export default Trendingseries;

const cssstyle = `
    .center .slick-center .slick-slide .slick-slide-image {
        color: #e67e22;
        opacity: 1;
        border:3px solid orange !important;
        transform: scale(1.1);
    }
    .slick-slide .small-imgs{
        height:380px;
        width:260px;
        border-radius:15px;
        object-fit:cover;
    }
    .slick-slide .large-imgs{
        width:100% !important;
        height:auto !important;
    }
    .carousel-up{
        position:absolute;
        height:100%;
        width:100%;
        top:200px;
        left:0;
        bottom:0;
        right:0;
        z-index:0;
    }
    .bg-carousel-height .slick-slider {
        margin: 0 0px !important;
    }
    .bg-carousel-height .slick-slide{
        padding:0px 0px !important;
    }
    .large-slider::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 0;
        height: 100%;
        background: linear-gradient( 90.63deg, rgb(15 2 2) 4.82%, rgb(16 7 9 / 50%) 16.33% );
        transition: all 0.3s ease;
    }`;
