import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import FavouriteList from "../component/Home/FavouriteList";
import MoviesFor from "../component/Home/MoviesFor";

function TopSearchPage() {
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="d-flex">
            <Sidebar />           
        </div>

        <FavouriteList heading="My List"/>
        <MoviesFor heading="Recommendation For You"/>

        <Section />
        <Footer/> 
    </div>
);
}export default TopSearchPage;
