import "../../style/Home/AboutUs.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import team1 from "../../images/team1.jpg";
import team2 from "../../images/team2.jpg";
import tvview from "../../images/tvview.png";
import mobileimg from "../../images/mobileimg.png";
import introall from "../../images/intro-all.png";
import everywhere1 from "../../images/everywhere1.png";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";
import {FaChevronRight} from "react-icons/fa";

function AboutUs(props) {
    const navigate = useNavigate()
    
    return (
    <Section className="bg-about">
        <div className="about-bg-image my-120">
            <h1 className="text-white text-center">About Us</h1>
            <span className="d-flex justify-content-center">
                <a onClick={()=>navigate('/')}><p className="mb-0">Home</p></a>
                <FaChevronRight className="text-white mt-1 mx-2"/>
                <p className="color-orange mb-0">About Us</p>
            </span>
        </div>
        <div className="relative pb-lg-5 px-100">
            <Container className="pt-80 services-section">
                <div>
                    <h1 className="text-white text-center">What Services We Provide</h1>
                </div>
                <Row className="pt-80 text-align-center">
                    <Col className="col-lg-6 col-md-6 col-12 pt-lg-5">
                        <div>
                            <h1 className="text-white font-w-600">Enjoy On Your TV</h1>
                            <h5 className="text-white">Watch on smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players and more.</h5>
                        </div>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-12">
                        <div className="services-images">
                            <img src={tvview} alt="tvview" />
                        </div>
                    </Col>
                </Row>
                <hr></hr>
                <Row className="pt-80 pb-lg-5 reverse text-align-center">
                    <Col className="col-lg-6 col-md-6 col-12">
                        <div className="services-images">
                            <img src={mobileimg} alt="mobileimg" />
                        </div>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-12 pt-lg-5">
                        <div>
                            <h1 className="text-white font-w-600">Download your shows to watch offline</h1>
                            <h5 className="text-white">Save your favourites easily and always have something to watch.</h5>
                        </div>
                    </Col>
                </Row>
                <hr></hr>
                <Row className="pt-80 pb-5 text-align-center">
                    <Col className="col-lg-6 col-md-6 col-12 pt-lg-5">
                        <div>
                            <h1 className="text-white font-w-600">Watch Everywhere</h1>
                            <h5 className="text-white">Stream unlimited movies and TV shows on your phone, tablet, laptop, and TV.</h5>
                        </div>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-12">
                        <div className="services-images">
                            <img src={introall} alt="introall" />
                        </div>
                    </Col>  
                </Row>
            </Container>
        </div>
        <div className="relative bg-darker py-5 px-100">
            <Container>
                <Row>
                    <Col className="col-lg-4 col-12 pt-lg-5">
                        <h1 className="color-orange text-center pt-lg-4">Our <br/>Masterminds</h1>
                    </Col>
                    <Col className="col-lg-8 col-12">
                        <Row>
                            <Col className="col-lg-4 col-6 col-md-4">
                                <div className="team-images mb-2">
                                    <img src={team1} alt="team1" />
                                </div>
                                <span className="text-center">
                                    <h5 className="text-white bg-text px-5 mt-2">CEO</h5>
                                </span>
                            </Col>
                            <Col className="col-lg-4 col-6 col-md-4">
                                <div className="team-images mb-2">
                                    <img src={team2} alt="team2" />
                                </div>
                                <span className="text-center">
                                    <h5 className="text-white bg-text px-5 mt-2">Designer</h5>
                                </span>
                            </Col>
                            <Col className="col-lg-4 col-6 col-md-4 justify-content-center">
                                <div className="team-images mb-2">
                                    <img src={team1} alt="team1" />
                                </div>
                                <span className="text-center">
                                    <h5 className="text-white bg-text px-5 mt-2">Developer</h5>
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </Section>
    );

}
export default AboutUs;
