
import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import UserHeader from "../component/Home/UserHeader";
import UserProfile from "../component/Home/UserProfile";

function UserPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />        
        <UserHeader />

        <div className="d-flex">
            <Sidebar />    
        </div>
        
        <UserProfile/>     
        <Section/>
        <Footer/>  
    </div>
    );
}

export default UserPage;
