import "../../style/Home/AboutUs.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import { Container, } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaChevronRight} from "react-icons/fa";
import { Section } from "../styles/Section.styled";

function PrivacyPolicy(props) {
    const navigate = useNavigate()
    
return (
    <Section>
        <div className="relative ">
            <div className="about-bg-image my-120">
                <h1 className="text-white text-center">Privacy Policy</h1>
                <span className="d-flex justify-content-center">
                    <a onClick={()=>navigate('/')}>
                        <p className="mb-0">Home</p>
                    </a>
                    <FaChevronRight className="text-white mt-1 mx-2"/>
                    <p className="color-orange mb-0">Privacy Policy</p>
                </span>
            </div>
            <div className="bg-darker py-5">
                <Container>            
                    <div className="">
                        <h2 className="text-center text-white">Privacy Statement</h2>
                    </div>
                    <div className="pt-4">
                        <h5 className="text-white">
                            1. What Personal Information About Users Does streamit Collect?
                        </h5>
                        <p className="pt-3 ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                        </p>
                    </div>
                    <div className="pt-4">
                        <h5 className="text-white">2. Access to Account and Profiles :</h5>
                        <p className="pt-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem
                        </p>
                    </div>
                    <div className="pt-4">
                        <h5 className="text-white">
                            3. Third Party Payment Gateway – Financial Information :
                        </h5>
                        <p className="pt-3 ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius 
                            quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id 
                            arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. 
                            Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. 
                            Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem.
                        </p>
                    </div>
                </Container>
            </div>
        </div>
    </Section>
);
}
export default PrivacyPolicy;
