import { createGlobalStyle } from "styled-components";
import * as theme from "./Theme.styled";

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.colors.background} !important;
    // color: ${({ theme }) => theme.colors.text};
    font-family: monospace;
    overflow-x: hidden;
  }

  // theme buttons color
  .light {
    background: transparent;
    padding:3px;
    color:#fff !important;
    line-height:20px !important;
  }
  .blue {
    background: transparent;
    padding:3px;
    color:#fff !important;
    line-height:20px !important;
  }
  .red {
    background: transparent;
    padding:3px;
    color:#fff !important;
    line-height:20px !important;
  }

  // active theme
  .active{
      // border: 3px solid ${({ theme }) => theme.colors.border} !important;
  }
`