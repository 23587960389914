import "../../style/Home/Geners.css";
import "../../style/Home/Common.css";
import "../../style/Home/TopTvShows.css";
import "../../style/Home/Trending.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import agent from "../../images/agent.jpg";
import deadwind from "../../images/deadwind.jpg";
import freddy from "../../images/freddy.jpg";
import extraction from "../../images/extraction.jpg";
import image1 from "../../images/image1.png";
import Slider from "react-slick";
import { Container} from "react-bootstrap";
import { useNavigate } from "react-router";

function Geners(props) { 
    const navigate= useNavigate();
    const settings1 = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        centerPadding: "-16px",
        margin:"30px",
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        slidesToShow: 4,
        swipeToSlide: true,
        arrows: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1500,
                settings: 
                {
                    slidesToShow:3.5,
                    slidesToScroll: 3.5,
                    infinite: true,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 1400,
                settings: 
                {
                    slidesToShow:4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 1200,
                settings: 
                {
                    slidesToShow:3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    centerPadding: "-20px",
                },
            },
            {
                breakpoint: 991,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    centerPadding: "-20px",
                },
            },
            {
                breakpoint: 850,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2.5,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: 
                {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 600,
                settings: 
                {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    centerPadding: "-10px",
                },
            },
            {
                breakpoint: 420,
                settings: 
                {
                    centerPadding: "-10px",
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className="hero-slick relative geners">
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <div className="thumbnail-slider-wrap">
                <style>{cssstyle}</style>
                <Container className="pt-40 px-100">
                    <div className="heading-text">
                        <a href="" className="text-decoration-none">
                            <h4 className="color-white my-0">{props.heading}</h4>
                        </a>
                    </div>
                    <hr></hr>
                    <Slider {...settings1} className="pt-3">
                        <div>
                            <div className="flip">
                                <div className="front front-bg">
                                    <a onClick={ ()=>navigate('/seeall')}> 
                                        <img className="slick-slide-image " src={agent} />
                                    </a>
                                    <h4 className="text-shadow font-w-600"> MOUNTAIN </h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front front-bg">
                                    <a onClick={ ()=>navigate('/seeall')}>
                                        <img className="slick-slide-image" src={image1} />
                                    </a>
                                    <h4 className="text-shadow font-w-600">DRAMAS</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front front-bg">
                                    <a onClick={ ()=>navigate('/seeall')}>
                                        <img className="slick-slide-image " src={deadwind} />
                                    </a>
                                    <h4 className="text-shadow font-w-600">ROMANTIC</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front front-bg">
                                    <a onClick={ ()=>navigate('/seeall')}>
                                        <img className="slick-slide-image " src={freddy} />
                                    </a>
                                    <h4 className="text-shadow font-w-600">HORROR</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flip">
                                <div className="front front-bg">
                                    <a onClick={ ()=>navigate('/seeall')}>
                                        <img className="slick-slide-image " src={extraction}  />
                                    </a>
                                    <h4 className="text-shadow font-w-600">ACTION</h4>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Container>
            </div>
        </div>
    );
}
export default Geners;
const cssstyle = ` 
.geners .slick-dots{
    position:relative !important;
    bottom:0px !important;
    display:none !important
}
.geners{
    margin-top:0px !important;
}`;
