import React from "react";
import "../../style/Home/SignIn.css";
import $ from "jquery";
import "../../style/Home/EpisodeTabs.css";
import Episodes from "./Episodes";
import Trailer from "./Trailer";
import MoreLike from "./MoreLike";
import { Container} from "react-bootstrap";
import { Section } from "../styles/Section.styled";

$(document).ready(()=> {
    $('.tabs > a.tab').on('click', (event) => {
         $(event.target).parent('.tabs').find('> a.tab.active').removeClass('active');
         $(event.target).addClass('active');
         if(!$(event.target.hash).length) return;
         $('body').stop().animate({
             scrollTop: $(event.target.hash).offset().top - $('#tabs').outerHeight()
         }, 300, 'swing');
    });
})

function EpisodeTabs() {
    return (
        <Section className="pt-lg-5">
            <Container className="px-100">
                <div id="tabs" className="responsive">
                    <div className="tabs">
                        <a href="#episodes" className="tab active text-decoration-none">
                            Episodes
                        </a>
                        <a href="#morelike" className="tab text-decoration-none">
                            More Like This
                        </a>
                        <a href="#trailer" className="tab text-decoration-none">
                            Trailer
                        </a>
                    </div>
                    <hr className="my-0"></hr>
                </div>
            </Container>
            <div>
                <div id="episodes" className="content">
                    <Episodes />
                </div>
                <div id="morelike" className="content">
                    <MoreLike />
                </div>
                <div id="trailer" className="content">
                    <Trailer heading="Trailer"/>
                </div>
            </div>
        </Section>
    );
}

export default EpisodeTabs;
