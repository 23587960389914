import "../../../node_modules/video-react/dist/video-react.css"; // import css
import "../../style/Home/Common.css"; 
import "../../style/Home/VideoPlayer.css"; 
import React from 'react';
import { Player } from 'video-react';
import reactplayer from "../../videos/react-player.mp4";
import { Section } from "../styles/Section.styled";

export default function VideoPlayer() {
  return (
    <Section>
      <div className="py-5 mt-3">
        <Player
        playsInline
        poster="/assets/poster.png"
        src={reactplayer}
        />
      </div>
      
    </Section>
  );
};

