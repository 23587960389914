import styled from "styled-components";

export const ThemeContainer = styled.div`
  background: #fff;
  position:fixed;
  right: 0;
  top: 150px;
  z-index: 1000;
  display:grid;
`;

export const ThemeButton = styled.button`
  padding: 5px 15px;
  margin:5px;
  border:none;
  &:hover {
    box-shadow: none;
`;