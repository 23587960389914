import "../../style/Home/Blogs.css";
import "../../style/Home/AboutUs.css";
import kgf from "../../images/kgf.jpg";
import dewil from "../../images/dewil.jpg";
import saaho from "../../images/saaho.jpg";
import loopf from "../../images/loopf.png";
import loopi from "../../images/loopi.png";
import loopt from "../../images/loopt.png";
import thewitcher from "../../images/thewitcher.jpg";
import squidgames from "../../images/squidgames.jpg";
import { Container} from "react-bootstrap";
import { useNavigate } from 'react-router';
import { Section } from "../styles/Section.styled";
import { FaChevronRight,FaRegCalendarAlt } from "react-icons/fa";

const Blogs = () => {
    const navigate = useNavigate();
    return (
        <>
        <Section>
            <div className="bg-about">
                <div className="about-bg-image my-120">
                    <h1 className="text-white text-center">Blogs</h1>
                    <span className="d-flex justify-content-center">
                        <a onClick={()=>navigate('/')}><p className="mb-0">Home</p></a>
                        <FaChevronRight className="text-white mt-1 mx-2"/>
                        <p className="color-orange mb-0">Blogs</p>
                    </span>
                </div>
            </div>
            <div className='bg-darker relative px-100'>
                <Container className='py-5'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-7 col-12 mb-4'>
                            <div className='blogs-images'>
                                <img src={thewitcher} alt="thewitcher" />                        
                            </div>
                            <div className='text-style'>
                                <p className='text-white mt-2 movie mb-0'>Series</p>
                                <a onClick={()=>navigate('/blogdetail')}>
                                    <h2 className='font-w-600 text-white mt-2 mb-0'>The Witcher</h2>
                                </a>
                                <p className='text-white mt-2 movie'>
                                    Date : 12-January-2020
                                </p>
                                <p className='text-white'>
                                    Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. 
                                    Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.
                                </p>
                            </div>
                            <hr className='my-5'></hr>
                            <div className='text-style'>
                                <h3 className='text-white movie'>Leave a Reply:</h3>
                                <form action="#" className="form-outline mt-4">
                                    <div class="form-row">
                                        <div class="input-data textarea">
                                            <textarea rows="8" cols="80" required></textarea>
                                            <br />
                                            <div class="underline"></div>
                                            <label for="">Write your Reply</label>
                                            <br />
                                        </div>
                                    </div>                                        
                                    <div class="form-row">
                                        <div class="input-data" >
                                            <input type="text" required />
                                            <div class="underline"></div>
                                            <label for="">Name</label>
                                        </div>
                                        <div class="input-data">
                                            <input type="email" required />
                                            <div class="underline"></div>
                                            <label for="">Email</label>
                                        </div>                                        
                                        <div class="input-data">
                                            <input type="text" required />
                                            <div class="underline"></div>
                                            <label for="">Website</label>
                                        </div>
                                    </div>                              
                                    <div class="form-row submit-btn">
                                        <div class="input-data">
                                            <div class="inner"></div>
                                            <input type="submit" value="Post Comment" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-5 col-12 justify-content-center mb-4'>
                            <div className='text-style'>
                                <h5 className='text-white movie'>Recent Posts</h5>
                            </div>
                            <hr></hr>
                            <div className='d-flex pt-4'>
                                <div className=''>
                                    <div className='recent-imgs'>
                                        <img src={squidgames} alt="squidgames" />     
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className=''>
                                        <h5 className='text-white mb-1'>Squid Games</h5>
                                        <h5 className='text-white'><FaRegCalendarAlt className='color-orange pe-1 mb-1'/>Date : 20-August-2023</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex pt-4'>
                                <div className=''>
                                    <div className='recent-imgs'>
                                        <img src={kgf} alt="kgf" />     
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className=''>
                                        <h5 className='text-white mb-1'>KGF- Chapter 2</h5>
                                        <h5 className='text-white'><FaRegCalendarAlt className='color-orange pe-1 mb-1'/>Date : 20-August-2023</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex pt-4'>
                                <div className=''>
                                    <div className='recent-imgs'>
                                        <img src={saaho} alt="saaho" />     
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className=''>
                                        <h5 className='text-white mb-1'>Saaho</h5>
                                        <h5 className='text-white'><FaRegCalendarAlt className='color-orange pe-1 mb-1'/>Date : 20-August-2023</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex pt-3'>
                                <div className=''>
                                    <div className='recent-imgs'>
                                        <img src={dewil} alt="dewil" />     
                                    </div>
                                </div>
                                <div className='ps-3'>
                                    <div className=''>
                                        <h5 className='text-white mb-1'>Dewil</h5>
                                        <h5 className='text-white'><FaRegCalendarAlt className='color-orange pe-1 mb-1'/>Date : 20-August-2023</h5>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-5'></hr>
                            <div className='text-style'>
                                <h5 className='text-white movie'>Follow Us On:</h5>
                            </div>
                            <ul className="list-unstyled d-flex pt-lg-3">
                                <li className="">
                                    <a href="">
                                        <img src={loopf} alt="final" />
                                    </a>
                                </li>
                                <li className="ps-3">
                                    <a href="">
                                        <img src={loopi} alt="final" />
                                    </a>
                                </li>
                                <li className="ps-3">
                                    <a href="">
                                        <img src={loopt} alt="final" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>                    
                </Container>
            </div>
        </Section>
        </>
    )
}
export default Blogs;