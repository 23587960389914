import { Container, Row, Col, video } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import {FaStar, FaPlay, FaPause,FaRegPlayCircle,FaRegPauseCircle } from "react-icons/fa";
import { Section } from "../styles/Section.styled";
import spiderman from "../../videos/spiderman.mp4";
import horror from "../../videos/horror.mp4";
import Slider from "react-slick";
import "../../style/Home/Indexhero.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import { NavLink, useNavigate } from "react-router-dom";

function VideoPlayer({ src, isPlaying }) {
    const ref = useRef(null);
        useEffect(() => {
            if (isPlaying) 
            {
                ref.current.play();
            } 
            else 
            {
                ref.current.pause();
            }
        });
        return <video ref={ref} src={src} loop playsInline />;
}

export default function Indexhero() {
    const navigate = useNavigate();
    const [isPlaying, setIsPlaying] = useState(false);
    const settings = 
    {
        // className: "center",
        // centerMode: true,
        // infinite: true,
        // centerPadding: "0px",
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        speed: 500,
    };

    return (
      <Section>
        <div className="hero-slick">
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <style>{cssstyle}</style>
            <Slider {...settings} className="d-none d-lg-block d-md-block">
                <div className="one">
                    <div className="bg-image two two1">
                       <div className="three">
                       <VideoPlayer isPlaying={isPlaying} src={horror} className="vd-player three" />
                        <Container className="px-100">
                            <div className="hero-content">
                                <Row>
                                    <Col className="col-lg-4 col-md-6 col-12">
                                        <h1 className="text-white">IMF 2</h1>
                                        <div className="extra-small-content d-none d-lg-block d-md-block">
                                            <p>
                                                Duration :<span className="color-orange ps-1 ">02:35:10</span>
                                            </p>
                                        </div>
                                        <div className="extra-small-content">
                                            <p>Ratings :</p>
                                            <p className="color-orange ps-1">
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                            </p>
                                        </div>
                                        <div className="features my-3 ">
                                            <p className="mb-0">Animation</p>
                                            <p className="mb-0 px-1 color-orange"> | </p>
                                            <p className="mb-0">Fantasy</p>
                                            <p className="mb-0 px-1 color-orange">|</p>
                                            <p className="mb-0">Adventure</p>
                                            <p className="mb-0 px-1 color-orange">|</p>
                                            <p className="mb-0">Kids</p>
                                        </div>
                                        <p className="">
                                            A documentary on Hulu explores the infamous criminal’s origins and
                                            attention-stealing international heists, leading up to his theft of an Austrian treasure
                                        </p>
                                        <div>                                           
                                        <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/detailinfo">   
                                             <button className="bg-orange px-3 py-1 rounded border-0 text-white"><p className="text-white mb-0">Preview</p></button>
                                        </NavLink>
                                        </div>
                                    </Col>
                                    <Col className="col-lg-8 col-md-6 col-12 d-flex justify-content-center ">
                                <button className="play-btn" onClick={() => setIsPlaying(!isPlaying) }>
                                            {isPlaying ? (<FaRegPauseCircle/>) : (<FaRegPlayCircle />)}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                       </div>
                    </div>
                </div>
                <div>
                    <div className="bg-image">
                        <VideoPlayer isPlaying={isPlaying} src={spiderman} className="vd-player" />
                        <Container className="px-100">
                            <div className="hero-content">
                                <Row>
                                    <Col className="col-lg-4 col-md-6 col-12">
                                    <a onClick={() => navigate("/video")}><h1 className="text-white">Spiderman</h1></a>
                                        <div className="extra-small-content " >
                                            <p>
                                                Duration : <span className="color-orange ps-1 "> 02:35:10 </span>
                                            </p>
                                        </div>
                                        <div className="extra-small-content">
                                            <p>Ratings :</p>
                                            <p className="color-orange ps-1">
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                            </p>
                                        </div>
                                        <div className="features my-3 ">
                                            <p className="mb-0">Animation</p>
                                            <p className="mb-0 px-1 color-orange">| </p>
                                            <p className="mb-0">Fantasy</p>
                                            <p className="mb-0 px-1 color-orange">|</p>
                                            <p className="mb-0">Adventure</p>
                                            <p className="mb-0 px-1 color-orange">|</p>
                                            <p className="mb-0">Kids</p>
                                        </div>
                                        <p className="">
                                            A documentary on Hulu explores the infamous criminal’s origins and
                                            attention-stealing international heists, leading up to his theft of
                                            an Austrian treasure
                                        </p>
                                        <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/detailinfo">   
                                             <button className="bg-orange px-3 py-1 rounded border-0 text-white"><p className="text-white mb-0">Preview</p></button>
                                        </NavLink>
                                    </Col>
                                    <Col className="col-lg-8 col-md-6 col-12 d-flex justify-content-center ">
                                        <button className="play-btn" onClick={() => setIsPlaying(!isPlaying) } >
                                        {isPlaying ? (<FaRegPauseCircle/>) : (<FaRegPlayCircle />)}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </Slider>
            <Slider {...settings} className="d-block d-lg-none d-md-none">
                <div>
                    <div className="bg-image">
                        <VideoPlayer isPlaying={isPlaying} src={horror} className="vd-player" />
                        <Container className="px-100">
                            <div>
                                <Row>
                                    <Col className="col-lg-4 col-md-6 col-12 mb-4 mt-2 text-center">
                                        <h1 className="text-white">IMF 2</h1>
                                        <span className="d-block d-lg-none d-md-none text-white">Duration : 02:35:10</span>
                                        <span className="d-block d-lg-none d-md-none text-white">Ratings :<span>
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                            </span>
                                        </span>
                                    </Col>
                                    <Col className="col-lg-8 col-md-6 col-12 d-flex justify-content-center ">
                                        <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/detailinfo">   
                                            <button className="play-btn" onClick={() => setIsPlaying(!isPlaying) } >
                                            {isPlaying ? (<FaRegPauseCircle/>) : (<FaRegPlayCircle />)}
                                            </button>
                                        </NavLink>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
                <div>
                    <div className="bg-image">
                        <VideoPlayer isPlaying={isPlaying} src={spiderman} className="vd-player" />
                        <Container className="px-100">
                            <div>
                                <Row>
                                    <Col className="col-lg-4 col-md-6 col-12 mb-4 mt-2 text-center">
                                        <h1 className="text-white">IMF 2</h1>
                                        <span className="d-block d-lg-none d-md-none text-white">Duration : 02:35:10</span>
                                        <span className="d-block d-lg-none d-md-none text-white">Ratings :<span>
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                                <FaStar className="fa-icon color-orange" />
                                            </span>
                                        </span>
                                    </Col>
                                    <Col className="col-lg-8 col-md-6 col-12 d-flex justify-content-center ">
                                        <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/detailinfo">   
                                            <button className="play-btn" onClick={() => setIsPlaying(!isPlaying) } >
                                            {isPlaying ? (<FaRegPauseCircle/>) : (<FaRegPlayCircle />)}
                                            </button>
                                        </NavLink>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </Slider>
        </div>
      </Section>
    );

}
const cssstyle = `
    .hero-slick .slick-prev:before{
        display:none !important; 
    }
    .hero-slick .slick-next {
        right: 0px !important; 
    }
    .hero-slick .slick-prev {
        left: 0px !important; 
    }
    .hero-slick .slick-next:before{
        display:none !important; 
    }
    .hero-slick .slick-slider {
        margin:0 0 !important;
    }
    .hero-slick .slick-slide {
        padding:0px;
        text-align:unset !important;
    }
    .hero-slick .slick-dots{
        position:relative;
        bottom:100px;    
        transition: all 0.3s;
    }
    .slick-dots li button:before{
        font-size:15px !important;
        color:#fff !important;
        opacity:unset !important;
        background:transparent !important;    
    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: orange !important;
        width:20px !important;
        content:'' !important;
        background:orange ;
        height:10px !important;
        top:3px;
        border-radius:30px !important;    
    }
    .slick-dots li{
        margin:0 2px !important;
    }
`;
