import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Faq from "../component/Home/Faq";

function FaqPage() {
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="section-flex">
            <Sidebar />        
        </div>

        <Faq />
        <Section />
        <Footer/> 
    </div>
    );
}

export default FaqPage;