import SignNew from "../component/Home/SignNew";
import {GlobalStyles } from "../component/styles/Global.styled";
import ScrollToTop from "../component/Home/ScrollToTop";

function SignInPage() {
return (
    <div className="App">
        <GlobalStyles />
        <ScrollToTop />
        <SignNew />
    </div>
    );
}

export default SignInPage;