import styled from "styled-components";

export const Sidebar = styled.header`
    background: ${({ theme }) => theme.colors.background};
    padding: 0px 20px;
    text-align: center;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text};

    a:hover {
        color: ${({ theme }) => theme.colors.hover};
    }
    .side-list ul li a .side-icons:hover {
        color: ${({ theme }) => theme.colors.active}!important;
    }
    .side-list ul li .active{
        border:none !important;
        color: ${({ theme }) => theme.colors.active}!important;
    }
    @media (max-width:830px){        
    .side-list{
        background :${({theme}) => theme.colors.hovergradient} !important;
    }
    }
`;
