import styled from "styled-components";

export const Footer = styled.footer`
  background: ${({ theme }) => theme.colors.footerbg};   
  padding: 20px 20px;
  color: hsl(0, 0%, 100%);

  a {
    color: hsl(0, 0%, 100%);
  }
`;