import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../../style/Home/Faq.css";
import { Container, Accordion} from "react-bootstrap";
import { useNavigate } from 'react-router';
import {FaPlay, FaChevronRight } from "react-icons/fa";
import { Section } from '../styles/Section.styled';

const Faq = () => {
    const navigate = useNavigate();
    return (
    <>
    <Section>
        <div className="bg-about">
            <div className="about-bg-image my-120">
                <h1 className="text-white text-center">FAQ's</h1>
                <span className="d-flex justify-content-center">
                    <a onClick={()=>navigate('/')}><p className="mb-0">Home</p></a>
                    <FaChevronRight className="text-white mt-1 mx-2"/>
                    <p className="color-orange mb-0">FAQ's</p>
                </span>
            </div>
        </div>
        <div className='bg-darker relative px-100'>
            <Container className='py-5'>
                <h1 className='text-white text-center'>Frequently Asked Questions</h1>
                <Row className='pt-5 mb-lg-3'>
                    <Col>
                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className='mb-3'>
                                    <Accordion.Header className='acc-header'>
                                        <h5 className='mb-0'><FaPlay className='me-2 mb-1'/>What Is Loop ?</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Loop is a streaming service that offers a wide variety of award-winning TV shows, movies, anime, documentaries and more – on thousands of internet-connected devices.
                                        You can watch as much as you want, whenever you want, without a single ad – all for one low monthly price. 
                                        There's always something new to discover, and new TV shows and movies are added every week!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='mb-3'>
                                    <Accordion.Header className='acc-header'>
                                        <h5 className='mb-0'><FaPlay className='me-2 mb-1'/>How Much Loop Costs ?</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Watch anywhere, anytime. Sign in with your Loop account to watch instantly on the web at Loop.com from your personal computer or on any internet-connected device that offers the 
                                        Loop app, including smart TVs, smartphones, tablets, streaming media players and game consoles.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='mb-3'>
                                    <Accordion.Header className='acc-header'>
                                        <h5 className='mb-0'><FaPlay className='me-2 mb-1'/>What can I watch on Loop ?</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Loop has an extensive library of feature films, documentaries, TV shows, anime, award-winning Loop originals, and more. Watch as much as you want, anytime you want.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" className='mb-3'>
                                    <Accordion.Header className='acc-header'>
                                        <h5 className='mb-0'><FaPlay className='me-2 mb-1'/>Where can I watch ?</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Watch anywhere, anytime. Sign in with your Loop account to watch instantly on the web at Loop.com from your personal computer or on any 
                                        internet-connected device that offers the Loop app, including smart TVs, smartphones, tablets, streaming media players and game consoles.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" className='mb-3'>
                                    <Accordion.Header className='acc-header'>
                                        <h5 className='mb-0'><FaPlay className='me-2 mb-1'/>How do I cancel ?</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Loop is flexible. There are no annoying contracts and no commitments. You can easily cancel your account online in two clicks.
                                        There are no cancellation fees – start or stop your account anytime.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Section>
    </>
    )
}
export default Faq;