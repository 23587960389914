import "../../style/Home/Navbar.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import SignIn from "./SignIn";
import Plan from "./Plan";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import final from "../../images/final.png";
import mobileicon from "../../images/mobileicon.png";
import $ from "jquery"; 
import { Container,} from "react-bootstrap";
import { useNavigate, NavLink } from 'react-router-dom';
import { FaThLarge, FaTimes} from "react-icons/fa";
import { Header } from "../styles/Header.styled";
import { Section } from '../styles/Section.styled';
import { blue, light, red } from '../styles/Theme.styled';
import { ThemeButton } from '../styles/ThemeSwitching.styled';
import ThemeContext from '../../context/ThemeContext';
import { useContext } from 'react';

$( document ).ready(function() {
    $(".navbar-container ul li a:not(:only-child)").click(function (e) {
        $('.navbar-container ul li a:not(:only-child)').siblings(".Navbar-dropdown").slideToggle("slow");
    
        $(".Navbar-dropdown").not($(this).siblings()).hide("slow");
        e.stopPropagation();
    });
    
    $("html").click(function () {
        $(".Navbar-dropdown").hide();
    });
});

function Navbar() {
    const navigate = useNavigate()
    const { selectedTheme, onThemeSelect } = useContext(ThemeContext);

    return (
    <Header>
        <nav className="navbar">
            <Container className="px-100">
                <div className="navbar-container main-nav">
                    <input type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <FaThLarge className="mobile-icon" />
                    </div>
                    <div className="Brand">
                        <a onClick={ ()=>navigate('/')}>
                            <img src={final} alt="final" />
                        </a>
                    </div>
                    <div className="mobile-logo">
                        <a onClick={ ()=>navigate('/')}>
                            <img src={mobileicon} alt="final" />
                        </a>
                    </div>
                    <ul className="menu-items">
                        <div className="Sign-btn Sign-show">
                            <a href="#">
                                <SignIn/>
                                <Plan />
                            </a>
                        </div>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Movies">
                                <Dropdown.Item as="button"><NavLink to="/movies">All Movies</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>   
                        <li className="d-flex mt-16">
                            <DropdownButton title="Series">
                                <Dropdown.Item as="button"><NavLink to="/series">All Series</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Shows">
                                <Dropdown.Item as="button"><NavLink to="/tvshows">All TV Shows</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li>
                            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/newandhot">
                                New & Hot
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/channels">                               
                                Channels
                            </NavLink>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Pages">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/aboutus')}>About Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/faqpage')}>FAQ's</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/contactpage')}>Contact Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/blogspage')}>Blogs</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/privacypage')}>Privacy Policy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/termspage')}>Terms & Use</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                    </ul>
                    <div className="Sign-btn Sign-desktop">
                        <SignIn />
                        <Plan />
                    </div>
                </div>
            </Container>
        </nav>
    </Header>
    );

}
export default Navbar;
