import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import Trending from "../component/Home/Trending";
import PopularShows from "../component/Home/PopularShows";
import Channels from "../component/Home/Channels";
import Popularseries from "../component/Home/Popularseries";
import MoviesFor from "../component/Home/MoviesFor";

function NewHotpage() {   
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="section-flex">
            <Sidebar />           
            <Indexhero/>
        </div>

        <Trending heading="New In Movies"/>
        <Popularseries heading="New To Try" />

        <PopularShows heading="New In Comedy" />
        <MoviesFor heading="New In Short Films"/>
        <Channels heading="New In Shows"/>

        <Section />
        <Footer/> 
    </div>
    );
}

export default NewHotpage;
