import "../../style/Home/Active.css";
import "../../style/Home/Common.css";
import "../../style/Home/SignIn.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Common.css";
import "../../style/Home/Navbar.css";
import $ from "jquery";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {FaArrowLeft, FaAngleRight} from "react-icons/fa";
import { Container } from "react-bootstrap";
import { Section } from "../styles/Section.styled";

function Active() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    $(document).ready(function () {
        var current_fs, next_fs, previous_fs;
        var opacity;
        var current = 1;
        var steps = $("fieldset").length;
        setProgressBar(current);
        $(".next").click(function () {
            current_fs = $(this).parent();
            next_fs = $(this).parent().next();
            //Add Class Active
            $("#progressbar li")
                .eq($("fieldset").index(next_fs))
                .addClass("active");
            //show the next fieldset
            next_fs.show();
            //hide the current fieldset with style
            current_fs.animate(
                { opacity: 0 },
                {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            display: "none",
                            position: "relative",
                        });
                        next_fs.css({ opacity: opacity });
                    },
                    duration: 500,
                }
            );
            setProgressBar(++current);
        });
        $(".previous").click(function () {
            current_fs = $(this).parent();
            previous_fs = $(this).parent().prev();
            //Remove class active
            $("#progressbar li")
                .eq($("fieldset").index(current_fs))
                .removeClass("active");
            //show the previous fieldset
            previous_fs.show();
            //hide the current fieldset with style
            current_fs.animate(
                { opacity: 0 },
                {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;
                        current_fs.css({
                            display: "none",
                            position: "relative",
                        });
                        previous_fs.css({ opacity: opacity });
                    },
                    duration: 500,
                }
            );
            setProgressBar(--current);
        });
        function setProgressBar(curStep) {
            var percent = parseFloat(100 / steps) * curStep;
            percent = percent.toFixed();
            $(".progress-bar").css("width", percent + "%");
        }
        $(".submit").click(function () {
            return false;
        });
    });

    return (
      <Section>
        <div className="d-flex justify-content-center bg-images">
          <Container>
            <div className="registration-form">   
              <a onClick={ ()=>navigate('/home')}>
                <p className="mb-0"><FaArrowLeft className="text-white"/> Back</p>
              </a>    
              <div className="register-form-content text-center">
                  <h5 className="text-white">Welcome!</h5>
                  <h2 className="text-white font-w-600">Unlimited movies, TV shows and more</h2>
                  <h5 className="text-white">Watch anywhere. Cancel anytime.</h5>
              </div>
              <div className="justify-content-center text-center pt-4">
                  <div className="row">
                    <div className="col-md-4 col-lg-4 col-12 mb-2">
                      <div className="plan-card">     
                        <ul className="list-unstyled py-4 px-3 plan-list ">
                          <span className="d-flex">
                            <span className="icons">
                              <FaAngleRight className="color-orange ico" />
                            </span>
                            <li className="text-white ps-2 text-start">Great video quality in 1080p</li>
                          </span>
                          <span className="d-flex">
                            <span className="icons">
                              <FaAngleRight className="color-orange ico" />
                            </span>
                            <li className="text-white ps-2 text-start">Watch on your TV, computer, mobile phone and tablet</li>
                          </span>
                          <span className="d-flex">
                            <span className="icons">
                              <FaAngleRight className="color-orange ico" />
                            </span>
                            <li className="text-white ps-2 text-start">Downloads available</li>
                          </span>
                        </ul>      
                        <a href="javascript:void(0)" className="mr-2 text-decoration-none color-orange plans-btn text-center">
                          <h5 className="color-orange font-w-600 mb-1">Select Free</h5>
                          <h5 className="color-orange mb-0">$ 38/ Month</h5>
                        </a>
                      </div>
                    </div> 
                    <div className="col-md-4 col-lg-4 col-12 mb-2">
                      <div className="plan-card">     
                          <ul className="list-unstyled py-4 px-3 plan-list ">
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Great video quality in 1080p</li>
                            </span>
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Watch on your TV, computer, mobile phone and tablet</li>
                            </span>
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Downloads available</li>
                            </span>
                          </ul>      
                          <a href="javascript:void(0)" className="mr-2 text-decoration-none color-orange plans-btn text-center">
                            <h5 className="color-orange font-w-600 mb-1">Select Basic</h5>
                            <h5 className="color-orange mb-0">$ 38/ Month</h5>
                          </a>
                      </div>
                    </div> 
                    <div className="col-md-4 col-lg-4 col-12 mb-2">
                      <div className="plan-card">     
                          <ul className="list-unstyled py-4 px-3 plan-list ">
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Great video quality in 1080p</li>
                            </span>
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Watch on your TV, computer, mobile phone and tablet</li>
                            </span>
                            <span className="d-flex">
                              <span className="icons">
                                <FaAngleRight className="color-orange ico" />
                              </span>
                              <li className="text-white ps-2 text-start">Downloads available</li>
                            </span>
                          </ul>      
                          <a href="javascript:void(0)" className="mr-2 text-decoration-none color-orange plans-btn text-center">
                            <h5 className="color-orange font-w-600 mb-1">Select Premium</h5>
                            <h5 className="color-orange mb-0">$ 38/ Month</h5>
                          </a>
                      </div>
                    </div> 
                  </div>
                  <div className="plan-next-btn text-center py-4">
                    <button type="button"  onClick={ ()=>navigate('/register')} className="text-white"><p className="mb-0">Next</p></button>
                  </div>
              </div> 
            </div>   
          </Container>
        </div>
      </Section>
    );
}

export default Active;

