import "../../style/Home/IntroDetail.css";
import "../../style/Home/Common.css";
import "../../style/Home/Navbar.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import $ from "jquery"; 
import headset from "../../images/headset.png";
import final from "../../images/final.png";
import html from "../../images/html.png";
import css3 from "../../images/css3.png";
import bootstrap from "../../images/bootstrap.png";
import react from "../../images/react.png";
import javascript from "../../images/javascript.png";
import swap from "../../images/swap.png";
import page2 from "../../images/page2.png";
import blue1 from "../../images/blue1.png";
import red1 from "../../images/red1.png";
import tvview from "../../images/tvview.png";
import introicon from "../../images/introicon.png";
import introall from "../../images/intro-all.png";
import SignIn from "./SignIn";
import Plan from "./Plan";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import mobileicon from "../../images/mobileicon.png";
import { FaThLarge, FaTimes,FaHeart} from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "../styles/Section.styled";
import { useNavigate, NavLink } from 'react-router-dom';
import IntroCarousel from "../Home/IntroCarousel";
import { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext';
import {light,blue,red} from "../styles/Theme.styled";
import {ThemeButton} from "../styles/ThemeSwitching.styled";
import React, { useState } from "react";

$( document ).ready(function() {
    $(".navbar-container ul li a:not(:only-child)").click(function (e) {
        $('.navbar-container ul li a:not(:only-child)').siblings(".Navbar-dropdown").slideToggle("slow");
    
        $(".Navbar-dropdown").not($(this).siblings()).hide("slow");
        e.stopPropagation();
    });
    
    $("html").click(function () {
        $(".Navbar-dropdown").hide();
    });
});

//---------- tabbing for navbar
$(document).ready(()=> {
    $('.tabs > a.tab').on('click', (event) => {
         $(event.target).parent('.tabs').find('> a.tab.active').removeClass('active');
         $(event.target).addClass('active');
         if(!$(event.target.hash).length) return;
         $('body').stop().animate({
             scrollTop: $(event.target.hash).offset().top - $('#tabs').outerHeight()
         }, 300, 'swing');
    });
})

function IntroDetail(props) {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const { selectedTheme, onThemeSelect } = useContext(ThemeContext);
    const navigate = useNavigate()    
    
return (
    <Section className="bg-intro">
        <div className="bg-black-trans main-intro-bg">
            {/*----------------- Navbar section ----------------------*/}
            <nav className="navbar">
                <Container className="px-100">
                    <div className="navbar-container">
                        <input type="checkbox" name="" id="" />
                        <div className="hamburger-lines">
                            <FaThLarge className="mobile-icon" />
                        </div>
                        <div className="Brand">
                            <a onClick={ ()=>navigate('/')}>
                                <img src={final} alt="final" />
                            </a>
                        </div>
                        <div className="mobile-logo">
                            <a onClick={ ()=>navigate('/')}>
                                <img src={mobileicon} alt="final" />
                            </a>
                        </div>
                        <ul className="menu-items mb-0">
                            <div className="Sign-btn Sign-show">
                                <a href="#">
                                    <SignIn/>
                                    <Plan />
                                </a>
                            </div>
                            <li className='d-flex pl-0'>
                                <DropdownButton title='Demo' className="button-hover demo">
                                    <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                                        <Dropdown.Item as='button' className="button-hover ps-0">
                                            <ThemeButton className={`light ${selectedTheme === light ? 'active': ''}`} onClick={() => onThemeSelect(light)}>
                                                Main Theme
                                            </ThemeButton>
                                        </Dropdown.Item>
                                    </NavLink>
                                    <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                                        <Dropdown.Item as='button' className="button-hover ps-0"> 
                                            <ThemeButton className={`red ${ selectedTheme === red ? 'active' : '' }`} onClick={() => onThemeSelect(red) }>
                                                Red Theme
                                            </ThemeButton>
                                        </Dropdown.Item>
                                    </NavLink>                                        
                                    <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                                        <Dropdown.Item as='button' className="button-hover ps-0">
                                            <ThemeButton className={`blue ${selectedTheme === blue ? 'active': ''}`} onClick={() => onThemeSelect(blue) }>
                                                Blue Theme
                                            </ThemeButton>
                                        </Dropdown.Item>
                                    </NavLink>
                                </DropdownButton>
                            </li>                                
                            <div id="tabs" className="">
                            <div className="intro-tabs">
                            <a href="#features" className="tab active text-decoration-none p-0">
                            <li className="d-flex text-white">
                                    Features
                            </li></a>
                            <a href="#support" className="tab active text-decoration-none p-0">
                            <li className="d-flex mt-1 text-white">
                                    Support
                            </li>
                            </a>
                            <a href="#document" className="tab active text-decoration-none p-0">
                            <li className="text-white mt-1">
                                Documentation
                            </li>
                            </a>                                                      
                            <a href="#document" className="tab active text-decoration-none p-0 purchase-sm">
                                <li className="text-white mt-1">
                                    Purchase
                                </li>
                            </a>
                            </div>
                            </div>
                        </ul>
                        <div className="list-unstyled purchase-view">                            
                        <a href="#document" className="tab active text-decoration-none p-0">
                            <li>
                                <button exact activeClassName="navbar__link--active" className="text-white purchase-button rounded border-0 px-2 py-1 mt-4" to="/newandhot">
                                    <p className="mb-0">Buy Now</p>
                                </button>
                            </li>
                            </a>
                        </div>
                    </div>
                </Container>
            </nav>
            {/*----------------- Movie ,tv shows section ----------------------*/}
            <Container className="py-5">
                <div className="text-center icon-image">
                    <img src={introicon} alt="introicon" />
                    <h3 className="text-white mt-5">
                        Movie, TV Shows, Video Streaming, <br/>React Template
                    </h3>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="main-screen-img pt-5 ">
                        <img src={tvview} alt="tvview" />
                    </div> 
                </div>  
            </Container>
        </div>
            {/*----------------- Choose color section ----------------------*/}
        <div>
            <Container className="py-5">
                <div className="text-center">
                    <h3 className="text-white">Choose the color you love</h3>
                    <p className="pt-3">
                        Choose a homepage to start navigating Loop.
                        <br/>
                        Build Strong & Impressive Website Using Loop Template.
                    </p>
                </div>
                <Row className="pt-lg-5">
                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="screen-imgs">
                        <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                            <ThemeButton className={`light ${selectedTheme === light ? 'active': ''}`} onClick={() => onThemeSelect(light) }>
                                <img src={page2} alt="page2" />
                            </ThemeButton>
                        </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="screen-imgs">
                        <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                            <ThemeButton className={`blue ${selectedTheme === blue ? 'active': ''}`} onClick={() => onThemeSelect(blue) }>
                                <img src={blue1} alt="blue1" />
                            </ThemeButton>
                        </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mb-4">
                        <div className="screen-imgs">
                        <NavLink exact activeClassName='navbar__link--active' className='navbar__link' to='/home'>
                            <ThemeButton className={`red ${selectedTheme === red ? 'active': ''}`} onClick={() => onThemeSelect(red) }>
                                <img src={red1} alt="red1" />
                            </ThemeButton>
                       </NavLink>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
            {/*----------------- A Lifetime Free lifetime updates section ----------------------*/}
        <div className="bg-black-trans">
            <Container className="py-5">
                <div className="text-center">
                    <h2 className="text-white">Enjoy <span className="intro-text">A Lifetime Free</span>  Updates! Additionally,</h2>
                </div>
            </Container>
        </div>
            {/*----------------- Stunning All pages section ----------------------*/}
        <div className="">
            <div className="pt-5">
                <Container >                
                    <div className="text-center">
                        <h3 className="text-white">Stunning All pages</h3>
                      <p className="pt-3">
                            Get all inner pages
                                <br/>
                            Access to add inner pages be it blogs, about us, log in sign up and many more.
                        </p>
                    </div>
                    <hr className="mb-0"></hr>
                    </Container>
                    <IntroCarousel/>
            </div>
        </div>
            {/*----------------- Theme Responsiveness section ----------------------*/}
        <div className="bg-black-trans">
            <Container className="py-5">
                <Row className="pb-lg-5 text-align-center">
                    <Col className="col-lg-6 col-md-6 col-12 pt-lg-5 pt-80 ">
                        <div>
                            <h5 className="intro-text">Theme Responsiveness</h5>
                            <h3 className="text-white font-w-600 pt-3">Fully Responsive and Retina Ready</h3>
                            <p className="text-white pt-3">Get an optional viewing experience across all major devices like desktop and mobile.
                            Loop is fully responsive and well-formatted with the Images, Video Player and text to be well scale up & down as per devices.</p>
                        </div>
                    </Col>
                    <Col className="col-lg-6 col-md-6 col-12">
                        <div className="all-images">
                            <img src={introall} alt="introall" />
                        </div>
                    </Col>  
                </Row>
            </Container>
        </div>
            {/*----------------- Loop  Core Features section ----------------------*/}
        <div className="content" id="features">
            <Container className="py-5">
                <div className="text-center">
                    <h3 className="font-w-600 text-white">Loop Core Features</h3>
                    <p className="pt-3">
                        Easy to customize code, well maintain,
                        <br/>
                        formatted & commented.
                    </p>
                </div>
                <Row className="pt-5">
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={html} alt="html" />
                            <p className="text-white mt-4 mb-0">HTML5</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={css3} alt="css3" />
                            <p className="text-white mt-4 mb-0">CSS3</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={react} alt="react" />
                            <p className="text-white mt-4 mb-0">React</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={swap} alt="swap" />
                            <p className="text-white mt-4 mb-0">Theme Switching</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3 pt-lg-4">
                        </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3 pt-lg-4">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={bootstrap} alt="bootstrap" />
                            <p className="text-white mt-4 mb-0">Bootstrap5</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3 pt-lg-4">
                        <div className="bg-darker text-center p-4 core-features pt-5">
                            <img src={javascript} alt="javascript" />
                            <p className="text-white mt-4 mb-0">JavaScript</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3 pt-lg-4">
                        </div>
                </Row>
            </Container>
        </div>
            {/*----------------- Quick and Fast Support section ----------------------*/}
        <div className="bg-black-trans py-5 content" id="support">
            <Container>
                <div className="text-center quick-support">
                    <img src={headset} alt="headset" />
                    <div className="text-center pt-4">
                        <h3 className="text-white">Quick and Fast Support</h3>
                        <p className="text-center pt-3 mb-0">
                            Need Technical help? Do not hesitate to write us at
                            <br/>
                            <span className="intro-text">support@sacredthemes.net</span> We will be happy to help.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
            {/*----------------- Et All The Demo Templates section ----------------------*/}
        <div className="intro-setup-image content" id="document">
            <Container className="py-5">
                <div className="text-center">
                    <p>Get all the demo templates, features and lifetime free updates.</p>
                    <h3 className="text-white">Buy Now & Launch Your Website Today.</h3>
                    <button exact activeClassName="navbar__link--active" className="text-white purchase-button rounded border-0 px-2 py-1 mt-4" to="/newandhot">
                        <p className="mb-0">Buy Now</p>
                    </button>
                </div>
            </Container>
        </div>
            {/*----------------- footer section ----------------------*/}
        <div className="bg-black-trans">
            <Container className="pt-2 pb-4">
                <div className="d-flex justify-content-between intro-footer">
                    <div className="intro-loop">
                        <a onClick={ ()=>navigate('/')}>
                            <img src={final} alt="final" />
                        </a>
                    </div>
                    <div>
                        <p className="pt-4 mb-0">© Copyright <span className="intro-text">Loop</span> 2023. Made with <FaHeart className="intro-text"/> by   <a onClick={ ()=>openInNewTab('https://sacredthemes.net/')}><b className="intro-text">SacredThemes</b></a>. </p>
                    </div>
                </div>
            </Container>
        </div>
    </Section>
);

}
export default IntroDetail;
