import "../../style/Home/Sidebar.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fontsource/inter';
import { useNavigate, NavLink} from 'react-router-dom';
import { Sidebar } from "../styles/Sidebar.styled";
import pipe from "../../images/pipe.png";
import {FaHome, FaSearch, FaRegHandshake, FaRegHeart, FaRegClock} from "react-icons/fa";

function Sidebars() {
const navigate = useNavigate()

return (
    <Sidebar>
        <nav className="side-list">
        <ul>
            <li>
                <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/home">
                    <span> <FaHome className="side-icons" /></span>
                </NavLink>
            </li>
            <li className="pipe">
            <span className="px-0">
                <img src={pipe} alt="pipe" />
            </span>
            </li>
            <li>
                <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/topsearch">
                    <span><FaSearch className="side-icons" /></span>
                </NavLink>
            </li>
            <li className="pipe">
            <span className="px-0">
                <img src={pipe} alt="pipe" />
            </span>
            </li>
            <li>
                <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/myfavourite">
                    <span><FaRegHeart className="side-icons" /></span>
                </NavLink>
            </li>
            <li className="pipe">
            <span className="px-0">
                <img src={pipe} alt="pipe" />
            </span>
            </li>
            <li>
                <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/continuewatch">
                    <span><FaRegClock className="side-icons" /></span>
                </NavLink>
            </li>
            <li className="icon-hide">
                <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/detailinfo">
                    <span><FaRegHandshake className="side-icons" /></span>
                </NavLink>
            </li>
        </ul>
    </nav>
    </Sidebar>
);
}
export default Sidebars;
