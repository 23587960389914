import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import thewitcher from "../../images/thewitcher.jpg";
import veronica from "../../images/veronica.jpg";
import welcome from "../../images/welcome.jpg";
import spiderman from "../../images/spiderman.jpg";
import { Container, Row, Col } from "react-bootstrap";
import {FaRegHeart,FaPlay} from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import { Section } from "../styles/Section.styled";
import { useEffect } from "react";

function MoreLike() {
const navigate = useNavigate();
const { pathname } = useLocation();

    return (
        <Section>
            <Container className="pt-40 px-100">
                <div className="heading-text">
                    <a href="" className="text-decoration-none">
                        <h4 className="color-white my-0">More Like This</h4>
                    </a>
                    <a onClick={ ()=>navigate('/seeall')} className="text-decoration-none">
                        <h4 className="color-white my-0">See All</h4>
                    </a>
                </div>
                <hr></hr>
                <Row className="pt-3">
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={spiderman} alt="spiderman" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Spiderman</h5>
                                            <div className="extra-small-content">
                                                <p className=""> 
                                                    Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={thewitcher} alt="thewitcher" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">The Witcher</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={veronica} alt="veronica" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Veronica</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-lg-3 col-md-3 col-6 mb-4">
                        <div className="col-zoom">
                            <div id="main">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={welcome} alt="welcome" /></a>
                                <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                                <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                                <div id="sub" className="d-none d-lg-block d-md-block">
                                    <div className="d-flex justify-content-between p-3">
                                        <div>
                                            <h5 className="text-white mb-0">Welcome</h5>
                                            <div className="extra-small-content">
                                                <p className="">
                                                    Duration :<span className="color-orange ps-1">02:35:10</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <FaRegHeart className="color-orange font-30 pt-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>   
            </Container>
        </Section>
    );

}
export default MoreLike;
