import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { Routing } from '../src/Routing/Index';
import './App.css';
import ThemeContext from './context/ThemeContext';
import {Route, Routes} from "react-router-dom";
import Homepage from "../src/pages/HomePage";
import MoviesPage from "../src/pages/MoviesPage";
import RegisterPage from "../src/pages/RegisterPage";
import SignInPage from "../src/pages/SignInPage";
import SeriesPage from "../src/pages/SeriesPage";
import TvShowsPage from "../src/pages/TvShowsPage";
import NewHotpage from "../src/pages/NewHotpage";
import ChannelsPage from "../src/pages/ChannelsPage";
import TopSearchPage from "../src/pages/TopSearchPage";
import MyFavouritePage from "../src/pages/MyFavouritePage";
import ContinueWatchPage from "../src/pages/ContinueWatchPage";
import SeeAllPage from "../src/pages/SeeAllPage";
import DetailInfoPage from "../src/pages/DetailInfoPage";
import AfterLoginPage from "../src/pages/AfterLoginPage";
import UserPage from "../src/pages/UserPage";
import PlansPage from "../src/pages/PlansPage";
import AboutUsPage from "../src/pages/AboutUsPage";
import FaqPage from "../src/pages/FaqPage";
import ContactUsPage from "../src/pages/ContactUsPage";
import BlogsPage from "../src/pages/BlogsPage";
import BlogDetailPage from "../src/pages/BlogDetailPage";
import PrivacyPage from "../src/pages/PrivacyPage"; 
import TermsPage from "../src/pages/TermsPage";
import IntroPages from "../src/pages/IntroPages";

function App() {
    const ctx = useContext(ThemeContext);
    return (
        <ThemeProvider theme={ctx.selectedTheme}>
            <div className='App'>
            <Routes>        
        <Route path="/home" element={<Homepage />} />
        <Route path="/movies" element={<MoviesPage />} />
        <Route path="/Series" element={<SeriesPage />} />
        <Route path="/tvshows" element={<TvShowsPage />} />
        <Route path="/newandhot" element={<NewHotpage />} />
        <Route path="/channels" element={<ChannelsPage />} />
        <Route path="/topsearch" element={<TopSearchPage />} />
        <Route path="/myfavourite" element={<MyFavouritePage />} />
        <Route path="/continuewatch" element={<ContinueWatchPage />} />
        <Route path="/seeall" element={<SeeAllPage />} />
        <Route path="/detailinfo" element={<DetailInfoPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/afterlogin" element={<AfterLoginPage />} />
        <Route path="/userpage" element={<UserPage />} />
        <Route path="/planspage" element={<PlansPage />} />         
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/faqpage" element={<FaqPage />} />
        <Route path="/contactpage" element={<ContactUsPage />} />
        <Route path="/blogspage" element={<BlogsPage />} />
        <Route path="/blogdetail" element={<BlogDetailPage />} /> 
        <Route path="/privacypage" element={<PrivacyPage />} /> 
        <Route path="/termspage" element={<TermsPage />} /> 
    </Routes>
        <Routes>        
        <Route path="/" element={<IntroPages />} />   
    </Routes>
            </div>
        </ThemeProvider>
    );
}
export default App;
