import Active from "../component/Home/Active";
import {GlobalStyles } from "../component/styles/Global.styled";
import ScrollToTop from "../component/Home/ScrollToTop";
function PlansPage() {
return (
    <div className="App">
            <GlobalStyles />
            <ScrollToTop />
        <Active />
    </div>
    );
}

export default PlansPage;
