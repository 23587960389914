import "../../style/Home/IntroDetail.css";
import "../../style/Home/Common.css";
import "../../style/Home/Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import {Navigation, Autoplay} from "swiper/modules";
import { Section } from "../styles/Section.styled";
import page1 from "../../images/page1.png";
import watch from "../../images/watch.png";
import plan from "../../images/plan.png";
import channnels from "../../images/channnels.png";
import form from "../../images/form.png";
import like from "../../images/like.png";
import blog from "../../images/blog.png";
import contact from "../../images/contact.png";
import faq from "../../images/faq.png";
import {light,blue,red} from "../styles/Theme.styled";
import React, { useState } from "react";

function IntroCarousel(props) {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    const [selectedTheme, setSelectedTheme] = useState(light);
    const HandleThemeChange = (theme) => {
      setSelectedTheme(theme);
    };
    const navigate = useNavigate()    
    
    return (
    <Section className="bg-intro">
        <div>
            <div className="py-lg-5 px-3">
                    <div className="py-4">
                        <Swiper modules={[Navigation, Autoplay]} spaceBetween={10} slidesPerView={4.5}
                            breakpoints=
                            {{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 1,
                                },
                                639: {
                                    slidesPerView: 2.5,
                                },
                                865: {
                                    slidesPerView: 2.5,
                                },
                                1000: {
                                    slidesPerView: 3,
                                },
                                1400: {
                                    slidesPerView: 3,
                                },
                                1500: {
                                    slidesPerView: 3.5,
                                },
                            }}
                            autoplay={true}
                            loop={true}
                            navigation
                            onSwiper={(swiper) => console.log()}
                        >
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={page1} alt="page1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={channnels} alt="channnels" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={form} alt="form" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={watch} alt="watch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={contact} alt="contact" />
                                </div>
                            </SwiperSlide>                            
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={plan} alt="plan" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={faq} alt="faq" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={like} alt="like" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="all-pages relative">
                                    <img src={blog} alt="blog" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
            </div>
        </div>
    </Section>
    );

}
export default IntroCarousel;
