import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import favourite from "../../images/favourite.png";
import { Container, Row, Col } from "react-bootstrap";
import { FaHeart, FaPlay} from "react-icons/fa";
import image3 from "../../images/image3.png";
import image2 from "../../images/image2.png";
import image1 from "../../images/image1.png";

function FavouriteList() {

    return (
        <Container className="mt-100 relative px-100 pt-sm-50">
            <div className="heading-text">
                <a href="" className="text-decoration-none"><h4 className="color-white my-0">My List</h4></a>
            </div>
            <hr></hr>
            <div className="pt-lg-5">
                <div className="text-center">
                    <a href=""><img src={favourite} alt="favourite" /></a>
                    <h4 className="color-white font-w-500 py-lg-5">You haven't added anything yet</h4>
                </div>
            </div>
            {/* <Row className="pt-3">
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={budha} alt="budha" /></a>
                            <button className="movies-btn "> <FaPlay /></button>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">KGF Chapter 2</h5>
                                        <div className="extra-small-content">
                                            <p className=""> 
                                                Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={image1} alt="image1" /></a>
                             <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Spider-Man</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={image2} alt="image2" /></a>
                             <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">The Mission</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={image3} alt="image3" /></a>
                             <a onClick={ ()=>navigate('/detailinfo')}> <button className="movies-btn "> <FaPlay /></button></a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Twilight</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>    */}
        </Container>
    );

}
export default FavouriteList;
