import { useState } from 'react';
import { Section } from '../styles/Section.styled';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../style/Home/EditUser.css";
import "../../style/Home/Common.css"; 

function EditUser() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
<>
    <Section className='px-100 bg-transparent'>
        <Button variant="primary" onClick={handleShow} className='text-white'> <p className='mb-0'>Edit</p></Button>
        <Modal show={show} onHide={handleClose} className='edit-modal'>
            <Modal.Header className='pt-lg-5'>
                <Modal.Title>
                    <h2 className="text-white font-w-600"> Edit Details </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center pt-3">
                    <form className="mb-4">
                        <div className="pt-2">
                            <input type="text" className="form-control input-bg mb-4 py-lg-3" id="your-email" name="your-email" required placeholder="Email or Phone Number" ></input>
                            <input type="password" className="form-control input-bg py-lg-3" id="your-email" name="Password" required placeholder="Password" ></input>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className='btn-edit'>
                <Button variant="" onClick={handleClose} className='bg-grey text-white'>
                    Cancel
                </Button>
                <Button variant="" onClick={handleClose} className='text-white bg-dark'>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    </Section>
</>
  );
}

export default EditUser;