import "../../style/Home/PopularShows.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import arcane from "../../images/arcane.jpg";
import daredevil from "../../images/daredevil.jpg";
import chenbyl from "../../images/chenbyl.jpg";
import hourseman from "../../images/hourseman.jpg";
import { Container, Row, Col } from "react-bootstrap";
import {  FaPlay,  FaRegHeart,} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Section } from "../styles/Section.styled";

function PopularShows(props) {
const navigate = useNavigate()

    return (
    <Section>
        <Container className="pt-40 px-100">
            <div className="heading-text">
                <a href="" className="text-decoration-none"> 
                    <h4 className="color-white my-0">{props.heading}</h4>
                </a>
                <a onClick={ ()=>navigate('/seeall')} className="text-decoration-none">
                    <h4 className="color-white my-0">See All</h4>
                </a>
            </div>
            <hr></hr>
            <Row className="pt-3">
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={arcane} alt="arcane" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Arcane</h5>
                                        <div className="extra-small-content">
                                            <p className=""> 
                                                Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={daredevil} alt="Dare Devil" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">The Witcher</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={chenbyl} alt="chenbyl" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Chenbyl</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={hourseman} alt="hourseman" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Hourse man</h5>
                                        <div className="extra-small-content">
                                            <p className="">Duration :<span className="color-orange ps-1">02:35:10</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>   
        </Container>
    </Section>
    );
}
export default PopularShows;
