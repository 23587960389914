import "../../style/Home/ContinueWatch.css";
import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import '@fontsource/inter';
import agent from "../../images/agent.jpg";
import welcome from "../../images/welcome.jpg";
import sintter from "../../images/sintter.jpg";
import saaho from "../../images/saaho.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "../styles/Section.styled";
import {FaHeart} from "react-icons/fa";

function ContinueWatch() {
    function getWatchPixels(movieTime, watchTime) {
        return (watchTime * 300) / movieTime;
    }
    
    return (
       <Section>
         <Container className="mt-100 px-100 pt-sm-50">
            <div className="heading-text">
                <a href="" className="text-decoration-none"><h4 className="color-white my-0">Continue Watching</h4></a>
            </div>
            <hr></hr>
            <Row className="pt-3">
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="">
                        <div id="main-continue">
                            <div className="continue-content">                                
                                <div className="content-overlay"></div>
                                <a href=""><img src={saaho} alt="saaho" /></a>
                                <div className="content-details fadeIn-left">
                                    <h5 className="text-white">00:25:50</h5>
                                </div>
                            </div>
                            <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Saaho</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <FaHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="">
                        <div id="main-continue">
                            <div className="continue-content">                                
                                <div className="content-overlay"></div>
                                <a href=""><img src={welcome} alt="welcome" /></a>
                                <div className="content-details fadeIn-left">
                                    <h5 className="text-white">00:25:50</h5>
                                </div>
                            </div>
                            <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Welcome</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <FaHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="">
                        <div id="main-continue">
                            <div className="continue-content">                                
                                <div className="content-overlay"></div>
                                <a href=""><img src={sintter} alt="sintter" /></a>
                                <div className="content-details fadeIn-left">
                                    <h5 className="text-white">00:25:50</h5>
                                </div>
                            </div>
                            <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Sintter</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <FaHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="">
                        <div id="main-continue">
                            <div className="continue-content">                                
                                <div className="content-overlay"></div>
                                <a href=""><img src={agent} alt="agent" /></a>
                                <div className="content-details fadeIn-left">
                                    <h5 className="text-white">00:25:50</h5>
                                </div>
                            </div>
                            <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Agent</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <FaHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
       </Section>
    );
}
export default ContinueWatch;
