import {GlobalStyles } from "../component/styles/Global.styled";
import {Section} from "..//component/styles/Section.styled";
import Header from '../component/Home/Header';
import Footer from '../component/Home/Footer';
import ScrollToTop from "../component/Home/ScrollToTop";
import Sidebar from "../component/Home/Sidebar";
import Indexhero from "../component/Home/Indexhero";
import Trending from "../component/Home/Trending";
import Blockbuster from "../component/Home/Blockbuster";
import Popularseries from "../component/Home/Popularseries";
import PopularShows from "../component/Home/PopularShows";
import Recommended from "../component/Home/Recommended";
import TopSearch from "../component/Home/TopSearch";
import Channels from "../component/Home/Channels";
import TopofWeek from "../component/Home/TopofWeek";
import Trendingseries from "../component/Home/Trendingseries";
import TopTvShows from "../component/Home/TopTvShows";

function HomePage() {    
return (
    <div>
        <GlobalStyles />
        <ScrollToTop />
        <Header/>   

        <div className="section-flex">
            <Sidebar />
            <Indexhero />
        </div>

        <Trending heading="Trending In Movies"/>
        <Blockbuster heading="Blockbuster Movies"/>

        <Trendingseries />                    
        <Popularseries heading="Populer In Series"/>

        <PopularShows heading="Populer Shows"/>
        <TopofWeek heading="Top 10 Series Of The Week"/>

        <TopTvShows />
        <Recommended />

        <TopSearch />
        <Channels  heading="Channels"/>
        
        <Section />
        <Footer/>
    </div>    
    );
}

export default HomePage;
