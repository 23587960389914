
import "../../style/Home/Episodes.css";
import "../../style/Home/Common.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cartoon from "../../images/cartoon.png";
import budhabg from "../../images/budhabg.png";
import { Container} from "react-bootstrap";
import { FaRegDotCircle } from "react-icons/fa";
import { useNavigate } from "react-router";

function Episodes() {
const navigate= useNavigate();
    return (
        <Container className="px-100 pt-40 relative">
            <Tabs defaultActiveKey="one" transition={false} id="noanim-tab-example" className="mb-3 episode-tabs" >
                <Tab eventKey="one" title="Season 1">
                    <div className="row"> 
                        <div className="col-lg-12 col-md-12 col-6">  
                            <div className="row mb-3">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="bg">
                                        <a onClick={ ()=>navigate('/detailinfo')}><img src={budhabg} alt="final" /></a>
                                        <div className="overlay">
                                            <span className="episode-text ps-3">
                                                <h5 className="font-w-600 color-orange text-left mb-1"> Family Man </h5>
                                                <p className="text-left mb-2">E1</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-6 col-12">
                                    <div className="py-2">
                                        <h5 className="text-white font-w-600">Family Man</h5>
                                        <div className="d-flex pt-lg-1">
                                            <span className="text-white extra-small-content d-none d-lg-block d-md-block">
                                                <p className="font-w-600 ">Episode 1</p>
                                            </span>
                                            <span className="text-white ps-3 extra-small-content d-none d-lg-block d-md-block">
                                                <span className="d-flex">
                                                    <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                    <p className="font-w-600">20 July 2023</p>
                                                </span>
                                            </span>
                                            <span className="text-white ps-3 extra-small-content d-none d-lg-block d-md-block">
                                                <span className="d-flex">
                                                    <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                    <p className="font-w-600 "> 30 min{" "}</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="extra-small-content pt-lg-2 d-none d-lg-block d-md-block">
                                            <p>
                                                When Rocky leaves for home that night,he sees a young girl named Marie hanging
                                                around a bad crowd and walks her home.On the way, Rocky lectures her about
                                                staying away from the wrong walks herhome. On the way, Rocky lectures her
                                                about staying away from the wrong walksher home. On the way, Rocky lectures her
                                                about staying away from the wrong
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-6">
                            <div className="row mb-3">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="bg">
                                    <a onClick={ ()=>navigate('/detailinfo')}>  <img src={cartoon} alt="final" /></a>
                                        <div className="overlay">
                                            <span className="episode-text ps-3">
                                                <h5 className="font-w-600 color-orange text-left mb-1">
                                                    Family Man
                                                </h5>
                                                <p className="text-left mb-2">E2</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-6 col-12">
                                    <div className="py-2">
                                        <h5 className="text-white font-w-600"> Family Man</h5>
                                        <div className="d-flex pt-lg-1 ">
                                            <span className="text-white extra-small-content d-none d-lg-block d-md-block">
                                                <p className="font-w-600 "> Episode 1</p>
                                            </span>
                                            <span className="text-white ps-3 extra-small-content d-none d-lg-block d-md-block">
                                                <span className="d-flex">
                                                    <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                    <p className="font-w-600"> 20 July 2023</p>
                                                </span>
                                            </span>
                                            <span className="text-white ps-3 extra-small-content d-none d-lg-block d-md-block">
                                                <span className="d-flex">
                                                    <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                    <p className="font-w-600 ">30 min</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div className="extra-small-content pt-lg-2 d-none d-lg-block d-md-block">
                                            <p>
                                                When Rocky leaves for home that night,he sees a young girl named Marie hanging
                                                around a bad crowd and walks her home.On the way, Rocky lectures her about
                                                staying away from the wrong walks herhome. On the way, Rocky lectures her
                                                about staying away from the wrong walksher home. On the way, Rocky lectures her
                                                about staying away from the wrong
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                   
                    </div>
                </Tab>
                <Tab eventKey="2" title="Season 2">
                    <div className="row mb-3">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="bg">
                                <a onClick={ ()=>navigate('/detailinfo')}><img src={cartoon} alt="final" /></a>
                                    <div className="overlay">
                                        <span className="episode-text ps-3">
                                            <h5 className="font-w-600 color-orange text-left mb-1">KGF 2</h5>
                                            <p className="text-left mb-2">E1</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6 col-12">
                                <div className="py-2">
                                    <h5 className="text-white font-w-600">KGF 2</h5>
                                    <div className="d-flex pt-lg-1">
                                        <span className="text-white extra-small-content">
                                            <p className="font-w-600 ">Episode 1</p>
                                        </span>
                                        <span className="text-white ps-3 extra-small-content">
                                            <span className="d-flex">
                                                <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                <p className="font-w-600">20 July 2023</p>
                                            </span>
                                        </span>
                                        <span className="text-white ps-3 extra-small-content">
                                            <span className="d-flex">
                                                <FaRegDotCircle className="mt-1 me-1 font-12" />{" "}
                                                <p className="font-w-600 ">30 min</p>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="extra-small-content pt-lg-2 d-none d-lg-block d-md-block">
                                        <p>
                                            Disney+ is the one-stop destination for your favorite movies and series from Dar Wars and National Geographic. Watch them all exc
                                            isney, Pixar, Marvel, Star Wars and National Geographic. Watch them all exclusively with Disney+ Hotstar
                                            rvel, Star Wars and National Geographic. Watch them all exclusively with Disney+
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </Tab>
            </Tabs>
        </Container>
    );

}
export default Episodes;
