import "../../style/Home/Footer.css";
import "../../style/Home/Common.css";
import "@fontsource/inter";
import loopf from "../../images/loopf.png";
import loopi from "../../images/loopi.png";
import loopt from "../../images/loopt.png";
import googleStore from "../../images/googleStore.png";
import AppStore from "../../images/AppStore.png";
import { Container, Row} from "react-bootstrap";
import { useNavigate, NavLink } from 'react-router-dom';
import { Footer } from "../styles/Footer.styled";

function Footers() {
const navigate = useNavigate();
    return (
        <Footer className="">
            <Container className="py-4 px-100">
                <Row className="text-align-center">
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="text-left">
                            <h4 className="text-white mb-0">About Loop</h4>
                            <div className="extra-small-content">
                                <p className="font-w-400 pt-lg-3">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitatio sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-3">
                        <div>
                            <h4 className="text-white text-left mb-0">Quick Links</h4>
                            <ul className="list-unstyled text-white pt-lg-3">
                                <a onClick={ ()=>navigate('/aboutus')} className="text-decoration-none text-white"><li className="mb-lg-1">About Us</li></a>
                                <a onClick={ ()=>navigate('/contactpage')} className="text-decoration-none text-white"><li className="mb-lg-1">Contact Us</li></a>
                                <a onClick={ ()=>navigate('/faqpage')} className="text-decoration-none text-white"><li className="mb-lg-1">FAQ's</li></a>
                                <a onClick={ ()=>navigate('/privacypage')} className="text-decoration-none text-white"><li className="mb-lg-1">Privacy Policy</li></a>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center mb-3">
                        <div className="text-left">
                            <h4 className="text-white text-left mb-0">Follow Us</h4>
                            <ul className="list-unstyled d-flex justify-content-between pt-lg-3">
                                <li className="">
                                    <a href="">
                                        <img src={loopf} alt="final" />
                                    </a>
                                </li>
                                <li className="">
                                    <a href="">
                                        <img src={loopi} alt="final" />
                                    </a>
                                </li>
                                <li className="">
                                    <a href="">
                                        <img src={loopt} alt="final" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <div className="text-left">
                            <h4 className="text-white text-left mb-0">Download App</h4>
                            <div className="pt-lg-3 row">
                                <div className="col-lg-6 col-12 footer-down-images mb-2">
                                    <a href="">
                                        <img src={googleStore} alt="googleStore" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-12 footer-down-images mb-2">
                                    <a href="">
                                        <img src={AppStore} alt="AppStore" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </Footer>
    );
}
export default Footers;
