import "../../style/Home/UserHeader.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import $ from "jquery"; 
import SignIn from "./SignIn";
import GoPro from "./GoPro";
import Plan from "./Plan";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import final from "../../images/final.png";
import budha from "../../images/budha.png";
import mobileicon from "../../images/mobileicon.png";
import { Container } from "react-bootstrap";
import { useNavigate, NavLink } from 'react-router-dom';
import { FaThLarge, FaTimes, FaUserAlt, FaPowerOff} from "react-icons/fa";
import { Header } from "../styles/Header.styled";

$( document ).ready(function() {
    $(".navbar-container ul li a:not(:only-child)").click(function (e) {
        $('.navbar-container ul li a:not(:only-child)').siblings(".Navbar-dropdown").slideToggle("slow");
    
        $(".Navbar-dropdown").not($(this).siblings()).hide("slow");
        e.stopPropagation();
    });
    
    $("html").click(function () {
        $(".Navbar-dropdown").hide();
    });
});

function UserHeader() {
    const navigate = useNavigate()

    return (
    <Header>
        <nav className="navbar">
            <Container className="px-100">
                <div className="navbar-container main-nav">
                    <input type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <FaThLarge className="mobile-icon" />
                    </div>
                    <div className="Brand">
                        <a onClick={ ()=>navigate('/')}>
                            <img src={final} alt="final" />
                        </a>
                    </div>
                    <div className="mobile-logo">
                        <a onClick={ ()=>navigate('/')}>
                            <img src={mobileicon} alt="final" />
                        </a>
                    </div>
                    <ul className="menu-items ps-0">
                        {/* <div className="Sign-btn Sign-show">
                            <a href="#">
                                <GoPro />     
                            </a>
                        </div> */}
                        <li className="user-header-sm">
                        <div className="user-header-sm">
                            <div className="user-header-img d-flex ">
                                <a onClick={ ()=>navigate('/userpage')}><img src={budha} alt="budha" /></a>
                                <DropdownButton title="" className="mt-lg-2">
                                    <Dropdown.Item as="button" className="d-flex"><FaUserAlt/><a onClick={ ()=>navigate('/userpage')} className="ps-2">Profile</a></Dropdown.Item>
                                    <Dropdown.Item as="button" className="d-flex"><FaPowerOff/><a onClick={ ()=>navigate('/home')} className="ps-2">Logout</a></Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Movies">
                                <Dropdown.Item as="button"><NavLink to="/movies">All Movies</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Series">
                                <Dropdown.Item as="button"><NavLink to="/series">All Series</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Shows">
                                <Dropdown.Item as="button"><NavLink to="/tvshows">All TV Shows</NavLink></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Horror</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Thriller</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Comedy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Romantic</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Action</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Cartoon</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Shortfilm</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                        <li>
                            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/newandhot">
                                New & Hot
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName="navbar__link--active" className="navbar__link" to="/channels">                               
                                Channels
                            </NavLink>
                        </li>
                        <li className="d-flex mt-16">
                            <DropdownButton title="Pages">
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/aboutus')}>About Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/faqpage')}>FAQ's</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/contactpage')}>Contact Us</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/contactpage')}>Privacy Policy</a></Dropdown.Item>
                                <Dropdown.Item as="button"><a onClick={ ()=>navigate('/seeall')}>Terms & Use</a></Dropdown.Item>
                            </DropdownButton>
                        </li>
                    </ul>
                    <div className="Sign-desktop mt-3">
                        <div className="user-header-img d-flex">
                            <a onClick={ ()=>navigate('/userpage')}><img src={budha} alt="budha" /></a>
                            <DropdownButton title="" className="mt-2">
                                <Dropdown.Item as="span" className="d-flex"><FaUserAlt className="mt-2"/><a onClick={ ()=>navigate('/userpage')} className="ps-2">Profile</a></Dropdown.Item>
                                <Dropdown.Item as="span" className="d-flex"><FaPowerOff className="mt-2"/><a onClick={ ()=>navigate('/home')} className="ps-2">Logout</a></Dropdown.Item>
                            </DropdownButton>
                        </div>            
                    </div>
                </div>
            </Container>
        </nav>
    </Header>
    );

}
export default UserHeader;
