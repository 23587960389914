import styled from "styled-components";
import "../../style/Home/Common.css"

export const Section = styled.header`
    background: ${({ theme }) => theme.colors.background} ;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.color} !important;

    // ----------------indexherojs file
    a:hover {
        color: ${({ theme }) => theme.colors.hover};
    }
    .color-orange {
        color: ${({ theme }) => theme.colors.color} !important;
    }
    .side-list ul li .active{
        border:none !important;
        color: ${({ theme }) => theme.colors.active}!important;
    }
    .play-btn{
        // border:1px solid ${({theme}) => theme.colors.color} !important;
        color: ${({ theme }) => theme.colors.color} !important;
    }
    .slick-dots li.slick-active button:before{
        color: ${({ theme }) => theme.colors.color} !important; 
        background: ${({ theme }) => theme.colors.color} !important;
    }
    // --------------------------------trending movies
    .movies-btn{
        border:1px solid ${({theme}) => theme.colors.border} !important;
        color: ${({ theme }) => theme.colors.color} !important; 
    }
    div#sub{
        background :${({theme}) => theme.colors.hovergradient} !important;
    }

    // ------------------------trending series
    .center .slick-center .slick-slide .slick-slide-image{
        border:3px solid ${({theme}) => theme.colors.border} !important;
    }

    // ------------------------top 10 of the week
    .top-10 h1{
        text-shadow:4px 4px 1px ${({theme}) => theme.colors.color} !important;
    }

    // ---------------------- about us
    .bg-text{
        background: ${({theme}) => theme.colors.gradient} !important;
        border-left: 4px solid ${({theme}) => theme.colors.border} !important;
    }

    // -------------------------- faqs
    .acc-header{
        background: ${({theme}) => theme.colors.color} !important;
    }
    button:hover{
        color:${({ theme }) => theme.colors.hover}!important;
    }

    // -------------------- contact us
    .address-border{
        border:1px solid ${({theme}) => theme.colors.border};
    }
    .address-border:hover{
        background:${({theme}) => theme.colors.contactradial} !important;
    }
    .input-data .underline:before{
        background:${({theme}) => theme.colors.color} !important;
    }
    .input-data input:focus ~ label, .textarea textarea:focus ~ label, 
    .input-data input:valid ~ label, .textarea textarea:valid ~ label{
        color:${({theme}) => theme.colors.text} !important;
    }
    .input-data label{
        color:${({theme}) => theme.colors.text} !important;
    }
    .form-outline{
        border:2px solid ${({theme}) => theme.colors.border} !important;
    }
    .input-data input, .textarea textarea{
        border-bottom: ${({theme}) => theme.colors.borderbottom} !important;
    }
    .submit-btn .input-data .inner{
        background: ${({theme}) => theme.colors.contactbtn} !important;
    }
    
    //  -------------------------------- Blogs
    .text-style .movie{
        background: ${({theme}) => theme.colors.gradient} !important;
        border-left: 4px solid ${({theme}) => theme.colors.border} !important;
    }

    // ----------------------------- sign in and register
    #msform .action-button{
        background: ${({theme}) => theme.colors.color} !important;
        color: ${({theme}) => theme.colors.text} !important;
    }
    .input-bg{
        border:1px solid ${({theme}) => theme.colors.border} !important;
    }
    .plan-card .plans-btn{
        border-top:2px solid ${({theme}) => theme.colors.border} !important;
    }
    .plans-btn:hover, .plans-btn:focus{
        background:${({theme}) => theme.colors.background} !important;
    }
    .plan-card:hover, .plans-btn:hover, .plans-btn:focus{
        box-shadow:${({theme}) => theme.colors.color} 2px 0 5px !important;
    }
    .plan-next-btn button{
        background:${({theme}) => theme.colors.contactradial} !important;
    }
    .edit-button button{
        background: ${({theme}) => theme.colors.btn} !important;
    }
    .btn-edit .btn {
        background:${({theme}) => theme.colors.btn} !important;
    }
    .form-control:focus, .form-control{
        border:1px solid ${({theme}) => theme.colors.border} !important;
    }
    
    //-------------------------------- episodes css
    .tabs a.tab.active {
        color: ${({theme}) => theme.colors.color} !important;
        border: none !important;
    }
    .tabs a.tab:hover {
        color: ${({theme}) => theme.colors.color} !important;
    }
    .tabs a {
        color: ${({theme}) => theme.colors.text} !important;
    }
    .episode-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
        color: ${({theme}) => theme.colors.color} !important;
    }
    .bg-orange{
        background-color:${({theme}) => theme.colors.btn} !important;
    }
    // ------------------------------ search header page
    .bg-only{
        
    }
    `;

