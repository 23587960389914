import "../../style/Home/HeaderSearch.css";
import "../../style/Home/Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/inter";
import { Container } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function SearchOnly() {
return (
    <Container className="px-100 pt-search">
        <div className="bg-only rounded rounded-pill shadow-sm mt-5 mb-4">
            <div className="input-group">
                <input type="text"  placeholder=""  className="form-control border-0 bg-only" />
                <div className="input-group-append">
                    <button id="button-addon1" type="submit" className="btn btn-link text-primary" >
                        <FaSearch className="text-white search-icon" />
                    </button>
                </div>
            </div>
        </div>
    </Container>
);    
}
export default SearchOnly;
