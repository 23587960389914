import "../../style/Home/Trending.css";
import "../../style/Home/Common.css";
import "@fontsource/inter";
import kgf from "../../images/kgf.jpg";
import inception from "../../images/inception.jpg";
import commando from "../../images/commando.jpg";
import saaho from "../../images/saaho.jpg";
import { Container, Row, Col } from "react-bootstrap";
import {FaPlay, FaRegHeart} from "react-icons/fa";
import { useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Section } from '../styles/Section.styled';

function VideoPlayer({ src, isPlaying }) {
    const ref = useRef(null);
        useEffect(() => {
            if (isPlaying) 
            {
                ref.current.play();
            } 
            else 
            {
                ref.current.pause();
            }
        });
        return <video ref={ref} src={src} loop playsInline />;
}

function Trending(props) {
const navigate = useNavigate()

return (
    <Section>
        <Container className="pt-40 px-100">
            <div className="heading-text">
                <a href="" className="text-decoration-none ">
                    <h4 className="color-white my-0">{props.heading}</h4>
                </a>
                <a onClick={ ()=>navigate('/seeall')} className="text-decoration-none">
                    <h4 className="color-white my-0">See All</h4>
                </a>
            </div>
            <hr></hr>
            <Row className="pt-3">
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={kgf} alt="kgf" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">KGF - Chapter 2</h5>
                                        <div className="extra-small-content">
                                            <p className=""> 
                                                Duration :<span className="color-orange ps-1"> 02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={commando} alt="commando" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Commando</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={inception} alt="inception" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Inception</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-3 col-md-3 col-6 mb-4">
                    <div className="col-zoom">
                        <div id="main">
                            <a onClick={ ()=>navigate('/detailinfo')}><img src={saaho} alt="saaho" /></a>
                            <a onClick={ ()=>navigate('/detailinfo')}> 
                                <button className="movies-btn "> <FaPlay /></button>
                            </a>
                            <button className="movies-btn d-block d-lg-none d-md-none"><FaPlay /></button>
                            <div id="sub" className="d-none d-lg-block d-md-block">
                                <div className="d-flex justify-content-between p-3">
                                    <div>
                                        <h5 className="text-white mb-0">Saaho</h5>
                                        <div className="extra-small-content">
                                            <p className="">
                                                Duration :<span className="color-orange ps-1">02:35:10</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaRegHeart className="color-orange font-30 pt-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>   
        </Container>
    </Section>
);    
}
export default Trending;
