import { createContext, useState } from 'react';
import { light } from '../component/styles/Theme.styled';

const ThemeContext = createContext({
    selectedTheme: light,
    onThemeSelect: (theme) => {},
});

export const ThemeContextProvider = (props) => {
    const [selectedTheme, setSelectedTheme] = useState(light);

    const themeHandler = (theme) => {
        setSelectedTheme(theme);
    };

    return (
        <ThemeContext.Provider
            value={{
                selectedTheme,
                onThemeSelect: themeHandler,
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
